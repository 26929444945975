
.ActionHeader {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.feedsContainerBox {
    text-align: center;
    background: #FFF;
    color: #2E637D;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    margin-bottom: 3rem;
  }
.ActionClickRows {
    background: var(--neutral-50, #FAFAFA);
    border-radius: 6px;
    padding: 8px 12px;
}
.ActionClickBtns {
    border: none;
    background: none;
    text-align: start;
    color: var(--neutral-700, #404040);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
}
.ActionClickRows:hover {
    background-color: #EEF2FF;
    color: #4338CA;
}

.ActionClickBadgeRow {
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: var(--neutral-100, #F5F5F5);
    mix-blend-mode: multiply;
}


/* Messaging Styles */
.Messages {
    background-color: white;
    position: sticky;
    height: auto;
}
.MessagingProfile {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}
.Messages .card {
    border: none;
    box-shadow: none;
}
.Messages .list-group-item  {
  border: none;
}
.MsgSearch {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    border: 1px solid var(--neutral-200, #E5E5E5);
    background: var(--generic-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    color: var(--neutral-400, #A3A3A3);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.emptyUsersDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    padding: 1rem;
    background: var(--generic-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.emptyUsersDiv h3 {
    color: var(--neutral-400, #40637d);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.MsgCardbody {
    box-shadow: none;
}
.MsglistItem {
    padding: 0;
    border: none;
    border-bottom: 1px dotted linen;
    margin-bottom: 5px;
    cursor: pointer;
}
.MsglistCardBody {
    padding: 0;
    min-height: 55vh;
    max-height: 58vh;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
.MsgBtnClick {
    border: none;
    color: #040404;
    border-radius: 0em;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.MsgBtnClickActive {
    color: #2F48FB;
    border-bottom: 0.2em solid #2F48FB;
    font-weight: bold;
    padding: 5px;
}
.MsgUserIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}
.liveUsername {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Adjust based on your font-size */
    max-height: 3em; /* line-height * 2 lines */
}
.liveUsercontent {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.createGroupButton {
    display: flex;
    width: 99px;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: #FACF39;
    border: 0.1em solid #FACF39;
    color: #1E2D39;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 6px;
}

.circleoverlap {
    display: flex;
    position: relative;
}

.circleoverlap img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: solid white 2px;
    margin-right: 2px;
    position: relative;
    background: teal;
  }
  
  .circleoverlapText {
    position: absolute;
    width: 100%;
    font-size: 0.75rem;
  }
  .unreadMessageCount {
    font-size: 0.5em;
  }
  .unreadMessageCounthide {
    font-size: 0.5em;
    visibility: hidden;
  }
  .nousersfoundfont {
    font-size: 14px;
    color: #1E2D39;
    color: #FF5370;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 10px;
}
.MessagingContainerCombo {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

/* Messaging Styles */

/* Create Users for Group  */

.SelectedUserGroup {
    background-color: #40637d;
    font-size: 20px;
    font-weight: 600;
    border: none;
}
.CartModalDialog {
    float: inline-end;
    top: 2.2em;
    right: 12em;
    width: 25%;
}
.CartLoadContent {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Required for the pseudo-element to be positioned correctly */
    z-index: 1; /* Ensure content is above the background */
}
.ModalbodyOverflow {
    max-height: 50vh;
    overflow-x: auto;
}
.UsersListdiv {
    padding: 0;
    min-height: 55vh;
    max-height: 60vh;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

/* Create Users for Group  */


/* Chat box CSS */
.ChatBox_container {
    padding: 0.1em;
    /* display: grid;
    grid-template-rows: 0vh 60vh 13vh; */
}
.ChatBox-container > .row {
    pad: 0.4em;
}
.chat_header {
    padding: 1rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: column;
}

.chat_body {
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.2rem;
    max-height: 60vh;
    align-items: flex-end;
    overflow: overlay;
}

.message {
  background: var(--buttonBg);
  color: white;
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message>span:nth-child(2){
    font-size: 0.7rem;
    color: var(--textColor);
    align-self: end;
}

.own {
  align-self: flex-end;
  border-radius: 1rem 1rem 0 1rem;
  background: linear-gradient(98.63deg, #24e4f0 0%, #358ff9 100%);
}

.chat_sender {
    background: var(--generic-white, #FFF);
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    align-items: center;
    gap: 0.2rem;
    padding: 0.3rem;
    border-radius: 1rem;
    align-self: end;
}
.chat_sender>input{
    height: 70%;
    background-color: var(--generic-white, #FFF);
    border-radius: 0.5rem;
    border: none;
    outline: none;
    flex  :1 ;
    font-size: 14px;
    padding: 0px 15px 0px 15px;
}
.chat_sender .react-emoji {
    border-radius: 5px;
}
.chat_sender>div:nth-of-type(1)
{
    background: var(--generic-white, #FFF);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    padding: 0em;
}
.inputMessagebar {
    border-radius: 5px !important;
}
.send_button {
    background: #714bff;
    border: solid #714bff;
    border-radius: 0.5rem;
    width: calc(20% - 8px);
    /* height: 40px; */
}
.sendImg {
    height: 15px;
}

.EmptyUserChatdivBox {
    background: white;
    text-align: center;
    font-size: 15px;
    color: red;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;    
}

.chat_sender>div{
    padding: 0px 2px 0px 2px;
}

.chat_sender .react-input-emoji--container {
    margin: 0;
}
/* Chat box CSS */


.Profileleft{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: -0.5rem;
}

.ProfileTop{
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.UserMessTitle{
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.UserMessDesc{
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.singleMsgBox {
    position: relative;
    max-height: 60vh;
    overflow: auto;
    padding: 0.2em;
}
.ChatItemCont {
    display: flex;
    gap: 5px;
}
.ChatItemContRev {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    text-align: end;
}

.ProfileChatImg{
    height: 30px;
    width: 30px;
    border-radius: 400px;
    border: 2px solid var(--Generic-White, #FFF);
}
.ProfileChatMsg{
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.ProfileChatTime{
    color: var(--Neutral-900, #171717);
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}
.ProfileImg {
    height: 40px;
    width: 40px;
    border-radius: 400px;
    border: 2px solid var(--Generic-White, #FFF);
}

.circleoverlap {
    display: flex;
    position: relative;
    white-space: nowrap;
}

.circleoverlapText {
    position: absolute;
    width: 100%;
    font-size: 0.75rem;
}
.MsgfooterSec {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.LinkClicks {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
} 

/* Your stylesheet.css or App.css */
.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .green {
    background-color: green;
  }
  
  .red {
    background-color: red;
  }