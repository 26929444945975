

.normalDisplay {
  display: none;
}
.NavHeader {
    background: var(--generic-white, #FFF);
    display: flex;
    padding: 0px 112px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.navbarHeader {
    background: var(--Generic-white, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: sticky;
}
.navbarContainer {
  height: 80px;
  padding: 0;
  margin: 0;
}
.logo {
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  padding: 2px;
}
.authContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    white-space: nowrap;
}
.authHeaderrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 0px 10px;
}
.authHeaderrow a {
    text-decoration: none;
}

.authHeaderrow a {
    color: #40637D;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.authHeaderrowBtns {
    color: #40637D;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border: none;
    box-shadow: none;
}
.authHeaderrowBtns:hover {
  color: #40637D;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
}
.HeaderNavLinks {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
}
.cartlogo {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.Links {
    color: #40637D;
    text-align: center;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}
.Links:hover {
  color: #40637D;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
}

.selectedTab {
    color: #2F485B;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline !important;
}
.cartCountBadge {
    position: absolute;
    top: 0.3em;
    right: 1.2em;
    background-color: yellow !important;
    color: black !important;
    padding: 2px 4px;
    font-size: 10px;
    border-radius: 50%;
    border: 0.2em solid #e8e81eab;
}
.profileContainerTextDiv {
    display: flex;
    align-items: normal;
    flex-flow: column;
    white-space: initial;
    text-align: justify;
 }
.profileUsername {
    color: #6366F1;
    text-align: start;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.profileUserBio {
    text-align: start;
    color: var(--neutral-500, #737373);
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.customdropdownmenu {
    border-bottom: 4px solid #FACF39 !important;
}
.roundedUserProfile {
    border: 0.2em solid aliceblue;
}
.UserProfileBoxsize {
    width: 12em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    text-align-last: start;
    --bs-dropdown-link-active-bg: none !important;
    outline: none;
    background-color: none;
    background: none;
}
.customdropdownmenu:hover {
  background: transparent;
}
.viewProfileBtn {
    width: 100%;
    background-color: #40637D;
    color: #FFF;
    text-align: center;
    text-align-last: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: none;
    box-shadow: none;
    padding: 1px 12px;
    border-radius: 25px;
}
.viewProfileBtn:hover {
  background-color: #40637D;
  color: #FFF;
  text-align: center;
  text-align-last: center;
  font-family: Open Sans;
  font-size: 15px;
  padding: 2px 12px;

}
.profileLogoutButtons {
    padding: 2px 14px;
    font-size: 16px;
    font-weight: 700;
    color: #40637D;
}
.profileLogoutButtons:hover {
  font-size: 18px;
  font-weight: bold;
  background: transparent;
}


/* SearchBar Styles  */

  .searchContainer {
    width: 100%;
    padding: 0;
  }
 .inputWrapper {
    position: relative;
    width: 100%;
    margin: 0 auto; /* Center align if needed */
  }
  
  #search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .searchIcon {
    color: var(--Neutral-400, #A3A3A3);
  }
  .inputWrapper input {
    width: 100%;
    padding: 8px 12px 8px 30px; /* Adjust padding to make space for the icon */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: var(--Spacing-3, 12px);
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #E5E5E5);
    background: var(--Generic-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    color: var(--Neutral-400, #000);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
.popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 12px;
    background: #E6ECF3;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    z-index: 1000;
    margin-top: 5px;
    padding: 10px;
}
.SpinnerPopDiv {
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.SpinnerPopDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/public/Content/Images/Svgs/logo_whitebackground.svg') no-repeat center center; 
    background-size: 25%;
    opacity: 0.5;
    z-index: 10;
}
  .popup ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .popup li {
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }
  
  .popup li:last-child {
    border-bottom: none;
  }
  
  .popup label {
    font-weight: bold;
  }
  
  .searchRecentdiv {
    border-radius: 12px;
    background: #FFF;
    padding: 10px;
  }
.headerlineStyle {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.recentItemsdiv {
    overflow-y: hidden;
    overflow-x: auto;
}
.workshopsItemsdiv {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    scrollbar-width: none;
}
.recentItem {
    display: inline-block;
    background: #FFF;
    padding: 5px;
    width: 90px;
    text-align: center;
}
.profilePic {
    width: 40px;
    height: 40px;
    text-align: center;
}
.profileNameTag {
    color: var(--Neutral-900, #171717);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-wrap: balance;
}
.profileDescTag {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-wrap: balance;
}
.workshopItemdiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background: #FFF;
    padding: 5px;
}
.ItemsDataTagsdiv {
    text-align: justify;
    line-height: normal;
}
.workshopItemdiv .profileNameTag {
    color: var(--Neutral-900, #171717);
    text-align: start;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-wrap: nowrap;
}
.workshopItemdiv .profileDescTag {
    color: var(--Neutral-500, #737373);
    text-align: start;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-wrap: nowrap;
}
.seeAlldiv {
    text-align: end;
    display: none;
}
.seeAllButton {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.searchFilterButtons {
    background: #E6ECF3;
    display: inline-flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    border: 1px solid #2E637D;
    box-shadow: 0px 5px 5px -5px rgba(47, 72, 251, 0.26);
    color: #2E637D;
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.searchButtonsActive {
    background-color: #2E637D;
    background: #2E637D;
    color: #FFF;
}
/* SearchBar Styles  */





/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .responsiveDisplay {
      display: none;
    }
    .NavHeader {
      background: var(--generic-white, #FFF);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px;
      border: none;
    }
    .navbarContainer {
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .responsiveBottomNavbar {
      background: var(--Generic-white, #FFF);
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      position: fixed;
      z-index: 9999;
    }
    .responsiveCanvasUser {
      border: none;
      background-color: transparent;
    }
    .responsiveCanvasUser:hover {
      border: none;
      background: var(--Generic-white, #FFF);
    }
    .authLogo {
      width: 70px;
      height: 70px;
    }
    .authHeaderrowBtns {
      margin-left: 5px;
      font-size: 14px;
    }
    .HeaderNavLinks {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 2px;
      background-color: aliceblue;
    }
    .HeaderNavLinks .btn {
      margin-bottom: 10px;
    }
    .cartCountBadge {
      top: -0.5em;
      right: 0.7em;
    }
    .OffCanvasUserProfile {
      width: 60px;
      height: 60px;
      border: 2px solid var(--Generic-White, #FFF);
    }
    .OffCanvasUsername {
      color: #C62E65;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .OffCanvasUserBio {
      color: var(--Neutral-500, #737373);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .canvasOverviewLinks {
      color: var(--Neutral-500, #737373);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0.5em;
    }
    .canvasOverviewLinks:hover {
      color: var(--Neutral-500, #000);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 0.5em;
    }
    .OffCanvasUserOverview {
      color: #40637D !important;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    .OffCanvasClose {
      color: #40637D !important;
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: bold;
      padding: 12px;
    }
    .inputWrapper input {
      width: 100%;
      padding: 10px 10px 10px 30px; /* Adjust padding to make space for the icon */
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: var(--Spacing-3, 12px);
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid var(--Neutral-200, #E5E5E5);
      background: var(--Generic-White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
      color: var(--Neutral-400, #000);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .overviewHeading {
      color: var(--neutral-900, #40637D);
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
  }

    /* bottom hand navigations styles */

    .BNContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .BHNIconStyles {
      color: #2F485B;
      font-size: 18px;
      font-weight: 500;
      transition: color 0.3s ease-in-out;
    }
    .offcanvasCustomStyle {
      width: 100% !important;
      text-align: center;
      bottom: 2.5em;
    }
    .BHNIconStyles:hover {
      color: #2F485B;
      font-size: 20px;
      font-weight: 700;
    }
    .BHNButtonStyles {
      background: transparent;
      border: none;
      border-radius: 0;
      outline: none;
    }
    .BHNButtonStyles:hover {
      background: transparent;
      border: none;
      border-radius: 0;
      outline: none;
    }
    .BHNButtonNavigators {
      display: block;
      margin-bottom: 10px;
      color: #1E2D39;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 1em;
    }
    .BHNButtonNavigators:hover {
      box-shadow: 0px 5px 5px -5px rgba(47, 72, 251, 0.26);
      color: #1E2D39;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 25px;
    }
    .createExperienceBtnRow {
      display: flex;
      padding: 4px 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      background: #FACF39;
  }
  .createExperienceBtn {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border: none;
      background: none;
  }
    /* bottom hand navigations styles */

  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */