

.signBackground {
    background-color: #E6ECF3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.signinContainers {
    overflow: auto;
    scrollbar-width: none;
}
.Form .Label {
    color: #1E2D39;
}
.viewportBox {
    height: 95vh;
    margin-top: 2em;
}
.smfooter {
    height: 5vh;
}

.signinSection {
    border-radius: 12px;
    background: #FFF;
    border: 0.1em solid #E6ECF3;
    box-shadow: 0px 20px 40px -8px rgba(16, 24, 40, 0.10);
    padding: 1em 2em;
    width: 500px;
    float: inline-end;
}
.logIntop {
    margin-top: 4em;
}
.signAlltop {
    margin-top: 2em;
}
.signinSection input {
    padding: 8px 12px;
}
.signinSection input[type="checkbox"] {
    width: 20px;
    height: 20px;
    padding: 8px;
    
}
.signinSection .form-check {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.signinSection form {
    color: #1E2D39;
    align-self: stretch;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.SubmitBtn {
    background-color: #40637d;
    color: #FFFFFF;
    border: none;
    border-radius: 0.2em;
}

.eyeIcon {
    border: 1px solid #dee2e6;
    border-left: none;
    z-index: 0;
}

.noButtonStyles {
    background: none;
    border: none;
    color: #404040;
}

.btnloginsetup {
    border-radius: 6px;
    background: #40637d;
    display: flex;
    height: 44px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.btnloginsetup:hover {
    background: #40637d;
    font-size: 18px;
    font-weight: 800;
    border-radius: 0;
}
.dashboardBtn {
    color: var(--generic-white, #FFF);
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.5px;
    border-radius: 6px;
    background: #40637d;
}

.socialLogins {
    background-color: white;
    border: none;
    padding: 1px;
}

.IconLogins {
    background-color: white;
    border: 0.1em solid white;
    padding: 0.5em;
    box-shadow: 0 8px 18px #40637d;
    margin-top: -3em;
}

.forgotPwdBtn {
    float: end;
    text-decoration: underline !important;
}




/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .signinContainers {
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: none;
    }
    .responsiveDisplay {
        display: none;
    }
    .signinSection {
        width: 100%;
        border-radius: 12px;
        background: #FFF;
        border: 0.1em solid #E6ECF3;
        box-shadow: 0px 20px 40px -8px rgba(16, 24, 40, 0.10);
        padding: 2em 2em;
        float: inline-end;
    }
    .logIntop {
        margin-top: 5em;
    }
    .IconLogins {
        margin-top: -5em;
    }
  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

    .signinContainers {
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: none;
    }
    .responsiveDisplay {
        display: none;
    }
    .signinSection {
        width: 100%;
        border-radius: 12px;
        background: #FFF;
        border: 0.1em solid #E6ECF3;
        box-shadow: 0px 20px 40px -8px rgba(16, 24, 40, 0.10);
        padding: 2em 2em;
        float: inline-end;
    }
    .logIntop {
        margin-top: 5em;
    }
    .IconLogins {
        margin-top: -5em;
    }
  }
  /* end of Tablets and Larger Screens */