/*  Page Name : App.css 
    Author : Created by Manojj M
 */

:root {
  --Generic-White: #FFFFFF; /* Define the CSS variable in :root to make it globally available */
  --generic-White: #FFFFFF;
}


body {
  margin: 0;
  padding: 0;
  color: #1E2D39;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.18px;
}

.a {
  text-decoration: none;
  cursor: pointer;
}
header {
  display: flex;
  padding: 0px 112px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--generic-white, #FFF);
}

.boxBody {
  border-radius: 12px;
  background: #FFF;
  padding: 20px 12px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  border-top: 4px solid #FACF39 !important;
}
.feedBodyBorder {
  border-radius: 12px;
  border-top: 4px solid #FACF39 !important;
}
.filterBoxBody {
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
.boxPadding {
  padding-right: 25px !important;
  padding-left: 25px !important;
  
}
.MessagingSection  {
  padding: 8px 12px !important;
}
.mainHeaderline {
  color: #40637D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.profileBoxContent {
  min-height: 70vh;
}
.row {
  --bs-gutter-x: 0rem !important;
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.stickySidebar {
  position: sticky;
  top: 85px;
}
.stickySidebarOverview {
  position: sticky;
  top: 290px;
}
.MainpartSetup {
  padding-top: 1em;
  background: #E6ECF3;
}
.HomeViewportHt {
  min-height: 90vh;
}
.viewportHt {
  min-height: 100vh;
}
.noPadding {
  padding: 0 !important;
}
.ZeroPadding {
  padding: 0 !important;
}
.RightPadding {
  padding-right: 0;
}
.LeftPadding {
  padding-left: 0;
}
.ZeroMargin {
  margin: 0 !important;
}
.minPadding {
  padding-left: 15px;
  padding-right: 15px;
}
.sidePadding {
  padding: 0px 10px;
}
.roundedUser {
  border: 0.1em solid #EEF2FF;
}
.borderStyle {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.borderStylefull {
  border: 1px solid lightgray;
  border-radius: 50px;
}
.navbarborder {
  border: 0.2em solid #E6ECF3;
}
.filtersBoxes {
  width: fit-content !important;
}
.styleHeader {
  color: var(--neutral-900, #171717);
  font-family: 'Josefin Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.56px;
}
.smallYellowLabel {
  color: #FACF39;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.Biglabel {
  color: #2F485B;
  font-family: Josefin Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.ContentLabel {
  color: #1E2D39;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;
}

.signupBigTags {
  color: var(--neutral-900, #171717);
  font-family: 'Josefin Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: -0.56px;
}
.loginBanner {
  border: 1px solid rgb(236, 236, 60);
  border-radius: 2em 2em 2em 2em;
  border-top: 0.3em solid rgb(236, 236, 60);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 0;
}

.CancelButtonSetup {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #FFF;
  border: 1px solid #2E637D;
  color: #40637D;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}
.CancelButtonSetup:hover {
  background: #FFF;
  color: #40637D;
  border: 2px solid #2E637D;
  font-size: 18px;
}
.SubmitButtonSetup {
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #2E637D;
  color: var(--Generic-White, #FFF);
  font-weight: 600;
  cursor: pointer;
}
.SubmitButtonSetup:hover {
  background: #2E637D;
  color: var(--Generic-White, #FFF);
  border: 2px solid #3a7897;
  font-size: 18px;
}

.LHNYellowButton {
  width: 100%;
  background: #FACF39 !important;
  color: #000;
  padding: 12px 20px;
  border-radius: 5px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1em;
  border: none;
}

/* Main Headers n Labels */

.mainHeaderStyle {
  font-family: Josefin Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.8px;
  margin: 0;
}
.mainHeaderBadgeStyle {
  margin-left: 15px;
}
.mainHeaderLabelStyle {
  color: var(--Neutral-700, #404040);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

/* Main Headers n Labels */


/* google btn CSS  */

.nsm7Bb-HzV7m-LgbsSe {
  border-radius: 25px !important;
}

.mainPad {
  padding: 0px 112px;
}

.signPadding {
  padding: 0em 5em;
}

.userProfileBox {
  background-color: white;
  padding: 0.5em 1.5em;
}

.AuthpageApp {
  background-color: white;
}

.sidebar {
  position: relative;
  background-color: white;
  color: #000;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.Messages body {
  color: var(--neutral-700, #404040);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.form-control {
  border-radius: 6px;
  border: 0.1em solid var(--neutral-200, #E5E5E5);
  background: var(--generic-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #1E2D39;
  font-size: 15px;
  cursor: pointer;
}
.form-check-input {
  border-radius: 6px;
  border: 0.2em solid var(--neutral-200, #E5E5E5);
  background: var(--generic-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  appearance: auto;
  cursor: pointer;
}
.btn {
  border-radius: 6px;
  border: 0.1em solid var(--neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  cursor: pointer;
}
.form-check {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}
.input-group .btn {
  z-index: 0;
}
a {
  text-decoration: none;
}
option {
  padding: 2px 0px;
}

.text-danger {
  color: red !important;
  align-self: stretch;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.text-sucess {
  color: green !important;
  align-self: stretch;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.yellowUpperLabel {
  color: #FACF39;
  text-transform: uppercase;
}

.yellowHeadingLabel {
  color: #FACF39;
}

.voiletHeadingLabel {
  color: #2F485B;
}

.blueHeadingLabel {
  color: #2F48FB;
  text-transform: uppercase;
}

.textUppercase {
  text-transform: uppercase;
}

.react-datepicker-wrapper {
  display: block !important;
}

.ModalCloseBtn {
  border: none;
  padding: 10px;
  cursor: pointer;
}
.btn-close {
  font-size: 0.6em;
}

.modal {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 1040;
}
.SendbirdApp {
  height: 80vh;
}

.toast {
  --bs-toast-border-color: none !important;
  --bs-toast-border-radius: none !important;
  --bs-toast-box-shadow: none !important;
  --bs-toast-padding-x: none !important;

}
.LoadSpinnersContent {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

#search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.BeatLoaderContainerBox {
  text-align: center;
  background: #FFF;
  color: #2E637D;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
  margin-bottom: 3rem;
}

@keyframes blink {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}

.blink-error {
  animation: blink 1s infinite;
}
