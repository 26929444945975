



.signBackground {
    background-color: #E6ECF3;
}
.Form .Label {
    color: #1E2D39;
}
.viewportBox {
    min-height: 95vh;
    margin-top: 5em;
    margin-bottom: 2em;
}
.smfooter {
    height: 5vh;
}
.viewportBoxProfile {
    min-height: 95vh;
    margin-top: 3em;
}
.viewportBoxInterests {
    min-height: 95vh;
    margin-top: 3em;
}
.signleftContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.signWelcomeline {
    color: #2F485B;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.signBigContentline {
    color: #40637D;
    font-family: Josefin Sans;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1.56px;
    text-transform: capitalize;
}
.signParaContent {
    color: #40637D;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.signupSection {
    border-radius: 12px;
    background: #FFF;
    border: 0.1em solid #E6ECF3;
    box-shadow: 0px 20px 40px -8px rgba(16, 24, 40, 0.10);
    margin-top: 2em;
    padding: 1em 2em;
    width: 500px;
    float: inline-end;
  }

.signupSection label {
    color: #1E2D39;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.Checkboxlabel {
    display: inline-block !important;
    font-weight: 100;
}
.Checkboxlabel label {
    color: #1E2D39;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
}

.faDotCircle {
    font-size: 1.4em;
}

.circleCompleted {
    font-size: 1.5em;
}

.currentRunIcon {
    border: 0.15em solid;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    position: relative;
  }
.blueBot {
    width: 40%;
    height: 40%;
    background-color: #40577d;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.nextRound {
    font-size: 1.5em;
}
.progressframe {
    display: flex;
    align-items: center;
    position: relative;
    /* width: 50px; */
  }
  
.progressframe .topline {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
.progressframe .rectangle {
    background-color: #404040;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
.progressframe .indicator {
    height: 24px;
    margin-bottom: -4px;
    margin-top: -4px;
    position: relative;
    width: 24px;
  }
  
.progressframe .bottomline {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: center;
    position: relative;
  }
  
.progressframe .strline {
    background-color: #e5e5e5;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
}
.BaseNameTag {
    color: #40637D !important;
    font-family: Inter !important;
    font-size: 14px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.NameTag {
    color: #40637D;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.progressframe .indicator2 {
    border: 2px solid;
    border-color: var(--neutral-200);
    border-radius: 24px;
    height: 24px;
    position: relative;
    width: 24px;
}

.noButtonStyles {
    background: none;
    border: none;
    color: #404040;
}
.interestItemOverflow {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-height: 45vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-width: none;
}

.interestItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 30%;
    height: 12vh;
    overflow: hidden;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;
    margin-top: 0.5em;
}
.interestItemName, .interestItem label {
    color: #40637D;
    text-align: center;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: break-spaces;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;

}
.selected label {
    position: relative;
    color: #FACF39 !important;
    font-size: 14px !important;
    /* background-color: #40637D !important; */
    border-radius: 50px;
    padding: 0.6rem 1.0rem;
    background: url('/public/Content/Images/icons/interests_check.png') no-repeat;
    background-position: center calc(100% + 5px);

}

.selected::after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 3rem;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 1.5em;
    height: 1.5em;
    background: url('/public/Content/Images/icons/icons-tick.png') no-repeat center bottom;
    background-size: contain;
}

.SubmitBtn {
    padding: 10px 20px;
    border-radius: 6px;
    background: #40637d;
}
.SubmitBtn:hover {
    background: #40637d;
    font-size: 16px;
    font-weight: 800;
    border-radius: 0;
}
.btnSkipStyles {
    background: none;
    border: none;
    color: #b3c4d1;
}
.OtpLabeltext {
    white-space: nowrap;
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.OtpBox {
    border: none;
    padding: var(--Spacing-3, 6px) 6px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.20);
    color: #47C653;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.OtpBox:focus {
    background: #FFF !important;
    color: #47C653 !important;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 1.10) !important;
    border: 2px solid var(--neutral-200, #47C653);
}
.blinkerror {
    background: #FFF !important;
    color: #40637d !important;
    font-weight: 900 !important;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 1.10) !important;
    border: 0.2em solid var(--neutral-200, #47C653);
}

.bsCheck {
    font-size: 1.4em;
    color: #40637d;
}
.crossCheck {
    font-size: 1.4em;
    color: red;
}

.eyeIcon {
    border: 1px solid #dee2e6;
    border-left: none;
    z-index: 0;
}
.sendOtpdiv {
    display: block;
    margin: 2px 4px;
}
.refferalDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
.refferalIdShow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--Spacing-0, 2px) 15px;
    gap: 6px;
    border-radius: 24px;
    border: none;
    background: #FACF39;
    color: #2F485B;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    pointer-events: all;
    cursor: crosshair;
}
.refferalIdShow:hover {
    background: #FACF39;
    color: #2F485B;
}
.sendOtp {
    background: #47C653;
    padding: 6px 8px;
    white-space: nowrap;
    border-radius: 6px;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
}
.sendOtp:hover {
    text-decoration: underline;
}

.ErrorInvalid {
    display: block;
    color: red;
}

.formRow {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
}

.termsbutton {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.80rem;
    background: none;
    border: none;
    cursor: pointer;
}
.termsbutton:hover {
    text-decoration: underline;
  }
.termsbutton:active {
    box-shadow: none;
    text-decoration: underline;
  }
  
.termsbutton:focus-visible {
    outline: none;
    text-decoration: underline;
  }
  .TooltipTextbox {
    text-align: start;
  }
  




  
/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .signupSection {
        margin-top: 0em;
        padding: 1em 2em;
        width: 100%;
        float: none;
    }
    .responsiveDisplay {
        display: none;
    }
    .viewportBox {
        min-height: 80vh;
        max-height: 90vh;
        margin-top: 6em;
        margin-bottom: 0;
    }

  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {


  }
  /* end of Tablets and Larger Screens */