
:global(.form-floating > :disabled ~ label::after) {
  background-color: #FFF;
}

.ESWhiteBackdrop {
    padding: 2em 2em;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);   
}
.ESWhiteBackdrop p {
  display: flex;  
  align-items: center;
  justify-content: center;
  gap: 10px;

}
.ESWhiteBackdrop .btn {
  color: var(--Generic-white, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.circleCompleted {
  font-size: 2em;
}

.currentProgressCircle {
  font-size: 2em;
}

.currentRunIcon {
  border: 0.15em solid;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  position: relative;
}

.blueBot {
  width: 40%;
  height: 40%;
  background-color: #2F485B;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progressframe {
    align-items: center;
    display: flex;
    position: relative;
    width: 76px;
  }
  
  .progressframe .topline {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
  .progressframe .rectangle {
    background-color: #404040;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }
  
  .progressframe .indicator {
    height: 44px;
    margin-bottom: -4px;
    margin-top: -4px;
    position: relative;
    width: 44px;
  }
  
  .progressframe .bottomline {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    justify-content: center;
    position: relative;
  }
  
  .progressframe .strline {
    background-color: #e5e5e5;
    flex: 1;
    flex-grow: 1;
    height: 2px;
    position: relative;
  }

  .progressframe .indicator2 {
    border: 0.15em solid;
    border-radius: 24px;
    height: 32px;
    position: relative;
    width: 32px;
  }
.labelname {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  margin-top: 1em;
}
.labelContent {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.itemContainer {
  cursor: pointer;
}
.ColImageWidth {
 width: 10%;
}
.ImagesSize {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  background: #000;
}
.MediaSettingCont {
  width: 100%;
  height: auto;
  min-height: 40vh;
  max-height: 40vh;
  border-radius: 12px;
  object-fit: cover;
  background: #000;
}
.BadgeSelection {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 1.6em;
  right: 0.2rem;
}
.ThumbnailVisibleImagesSize {
  width: 95%;
  height: 16em;
  background: rgba(230, 236, 243, 0.50);
  border-radius: 12px;
}
.ThumbnailImagesSize {
  width: 100px;
  height: 100px;
}
.PreviewThumbnail {
  width: 100%;
  height: 18em;
}
.BtnESbgColor {
  background-color: #F2F2F2;
  border-color: lightgray;
  padding: 0.4em 1.2em;
  gap: 12px;
}
.BtnESbgColor:hover {
  background-color: #F2F2F2;
  color: #FFF;
}

  .uploadIcondiv {
    width: 45px;
    height: 45px;
    position: absolute;
    top: -1.5em;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 0px 8px 24px -3px #060462;
  }
  .uploadSectiondiv {
    width: 300px;
    height: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 15px;
    border-radius: 6px;
    background: rgba(230, 236, 243, 0.50);
  }
  .uploadThumbnailSection {
    width: 180px;
    height: 90px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 15px;
    border-radius: 6px;
    background: rgba(230, 236, 243, 0.50);
  }
  .uploadbtnImage {
    width: 25px;
    height: 25px;
  }
  .uploadNameTag {
    color: var(--Neutral-900, #171717);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
  }

  .RectBox {
    background-color: #eef2ff;
    padding: 0.9em;
  }
  .noImageIndic {
    background-color: #666666;
  }
  
  .filesBtn {
    color: var(--primary-600, #000);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 24px;
    background: var(--primary-100, #FACF39);
    padding: 2px 10px;
    border: none;
  }

  .previewExpBtn {
    background-color: #666666;
    height: 30vh;
    border-radius: 80px;
  }
  .previewCloseStyleBtn {
    text-align: center;
    font-size: 16px;
    border-radius: 30px;
    color: #FFF;
    background: #0EB65B;
    font-weight: 600;
    border: 1px solid #F2F2F2;
    padding: 10px 25px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  }

.floatingLabel {
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 8px;
    background: var(--generic-white, #FFF);
}
.floatingFormControl {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
    box-shadow: none;
    text-transform: capitalize;
}
.floatingFormSelect {
  color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
    box-shadow: none;
}
.floatingSelectLabel {
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 8px;
    background: var(--generic-white, #FFF);
    color: var(--neutral-500, #737373);
}
.floatingFormSelect:disabled {
  background: var(--generic-white, #FFF);
}
.floatingFormSelect:disabled .form-label::after {
  background: var(--generic-white, #FFF);
}
.floatingLabel {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    
  }
  .FiEdit {
    padding: 1.3rem 0.75rem;
    border: none;
    border-radius: 0;
    background: none;
    margin-left: -2.5em;
  }
  .FiEditImg {
    border: none;
    border-radius: 0;
    background: none;
    margin-bottom: 1em;
    justify-content: end;
  }

  .inputField {
    border-right: none;
    border: none;
    box-shadow: none;
    background: white;
    border-radius: 0.2em;
    padding: 2.5em 1em;
  }

  .inputFieldPad .inputFieldPadding {
    padding: 2.5em 1em;
  }

.sequenceIndicator {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.selected {
  background-color: #f0f0f0;
}

.errorMessage {
  color: red;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.successMessage {
  color: green;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  background: #EEF2FF;
  margin-bottom: 1em;
}
.ExperienceDashBtn {
  text-decoration: underline 1px #2F485B;
  color: #0EB65B;
}
.ExperienceDashBtn:hover {
  text-decoration: underline 2px #2F485B;
  font-size: 18px;
  cursor: pointer;
}
.formlabel {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
  margin-top: 10px;
}

.formControl {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  background: var(--generic-white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #666;
  resize: none;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* styles.css */
.placeholderColor::placeholder {
  color: #888; /* or any color you prefer */
}

.formControl .placeholderColor::placeholder {
  color: #888; /* or any color you prefer */
}

.formSelect {
  appearance: auto;
  padding: 8px 5px;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.react-beautiful-dnd-draggable {
  margin: 0;
  transition: background-color 0.2s;
}

.react-beautiful-dnd-draggable:hover {
  background-color: #f0f0f0;
}

.text-#2F485B {
  color: #2F485B;
}

.blurEffect {
  filter: blur(2px);
}

.previewExpMsg {
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 0.6em 1em;
  border-radius: 5px;
  position: absolute;
  top: 27em;
  left: 47em;
  z-index: 1;
  cursor: pointer;
}

.previewExpBtn {
  text-align: center;
  margin-top: 10px;
}

.inlineBlock {
  padding: 0.5em;
}

.centeredText {
  color: #2F485B;
  text-align: center;
}

.dropdownMediatype {
  background-color: #eef2ff;
  border: none;
}

.verticalLine {
  border-right: 0.2em solid #E6ECF3;
  margin-right: 2em;
  
}
.BankformCheck {
  display: block;
}

.HoverBackOption {
  color: #40637D;
}
.HoverBackOption:hover {
  font-size: 1.5em;
  font-weight: 900;
  color: #40637D;
}
.workshopContainerdiv {
  position: relative;
  margin: 0 auto;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
.workshopThumbnailImage {
  width: 100%;
  height: 380px;
  border-radius: 12px;

}
.HoverBackOptiondiv a {
  color: #40637D;
}
.productDetailsBox {
  width: 95%;
  height: 130px;
  padding: 10px;
  border-radius: 12px;
  background: #40637D;
  box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
  pointer-events: none;
}
.ProductDetailsNameTagSetup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IconSetup {
  width: 34px;
  height: 34px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid #FACF39;
  padding: 5px;
}
.IconSetImage {
  width: 18px;
  height: 18px;
}
.previewProfileImg {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 1000px;
  border: 2px solid var(--Generic-White, #FFF);

}
.flexStyleforUserdiv {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 10px;
}
.productCaption {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.productDescription {
  display: inline;
  padding: 2px 10px;
  border-radius: 24px;
  background: var(--primary-100, #E0E7FF);
  color: var(--primary-600, #4F46E5);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productSellername {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}
.productSellerdesc {
  text-align: justify;
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}
.ProductBoxLabel {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.ProductBoxlabelValue {
  color: #FACF39;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.toggleDropdowndiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  color: #40637D;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.toggleDropdownClick {
  background: transparent;
  color: #1E2D39;
  outline: none;
  border: none;
  padding: 0px 20px;
}
.toggleToastStyles {
  width: 90%;
  max-width: 90%;
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.PaymentTermsdiv {
  margin-left: 2em;
  margin-right: 0em;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.PaymentTermsdiv ul li {
  color: #1E2D39;
  list-style: square;
  list-style: disc;
}
.PaymentTermsHeaderDiv {
  background: #E6ECF3;
  margin-left: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.PaymentTermsHeaderFont {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: normal;
}
.PaymentTermsfont {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0rem 1rem;
  text-align: start;
  letter-spacing: normal;
}
.PaddingSetupRow {
  padding: 0em 0em 0em 2em;
}
.PaymentTermsLabel {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.CustomCheckbox {
  width: 60px;
  height: 15px;
  cursor: pointer;
}
.CustomCheckbox:checked::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px; /* Adjust to fit inside the checkbox */
  height: 10px; /* Adjust to fit inside the checkbox */
  background-color: #000; /* Customize checkmark color */
  border-radius: 2px; /* Adjust to fit the border-radius */
}

.LinkTagDirect {
  color: #2F485B;
  font-size: 15px;
  font-weight: 800;
  text-decoration: underline;
  text-decoration-color: lightgray;
}
.LinkTagDirect:hover {
  color: #2E637D;
  font-size: 15px;
  font-weight: 900;
}
.PublishSubmitButtons {
  background: #2E637D;
  border: none;
  border-radius: 6px;
  display: inline-flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.PublishSubmitButtons .disabled {
  background: #7a909b;
  pointer-events: all;
}
.PublishSubmitButtonEdit {
  background: transparent;
  border-radius: 6px;
  border: 1px solid #2E637D;
  color: #2E637D;
  outline: none;
  border-radius: 6px;
  display: inline-flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.countrySelectform {
  width: 30%;
}


/* AccountSuccess.js styles */
.stripeMsgHeader {
  align-self: stretch;
  color: var(--primary-500-main, #2F485B);
  text-align: center;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.Background {
  background-color: #E6ECF3;;
}

.contentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.stripeProceedButton {
  border-radius: 6px;
  background: #2E637D;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Generic-White, #FFF);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.stripeAccountStatus {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}
.stripeAccountContent {
  color: #1E2D39;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.stripeStatusIcon {
  width: 56px;
  height: 56px;
  margin-bottom: 0.5em;
}
.AprooveImg {
  width: 96px;
  height: 96px;
}

.viewportSetup {
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerSec {
  height: 5vh;
}
.dashboardBtn {
  padding: 12px 20px;
  border-radius: 6px;
  background: #2F485B;
  color: var(--generic-white, #FFF);
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.height400px {
  height: 400px;
}

/* AccountSuccess.js styles */






  /* General Media Query for Mobile Screens */
  @media (max-width: 767px) {

  }
  /* end of Mobile Screens */
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */