/* Responsiveness.css

Author : MKK */
/* Media queries for Multiple screens */



  /* Note  */
  /* General Media Query for Mobile Screens */
  @media (max-width: 767px) {

  }
  /* end of Mobile Screens */
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */



  



/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .container, .container-fluid {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }
    .authLogo {
      width: 70px;
      height: 70px;
    }
    .mainPad {
        padding: 16px 16px;
    }
    .Biglabel {
        color: #40637D;
        text-align: center;
        font-family: "Josefin Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.48px;
    }
    .ContentLabel {
        color: #404040;
        text-align: center;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .ResponsiveCenteredAlignedButtons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-self: center;
        gap: 5px;
    }
    .MainpartSetup {
        padding-top: 0em;
        background: #E6ECF3;
    }
    .minPadding {
        padding: 0;
    }
    .responsiveMinPadding {
        padding-left: 15px;
        padding-right: 15px;
    }
  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

    .mainPad {
        padding: 0px 0px;
    }

  }
  /* end of Tablets and Larger Screens */



