/* Auth.css 
Created by Manojj M */



.responsiveMainBannerSetup {
  display: none;
}
.AuthHeader {
  background: var(--generic-white, #FFF);
  display: flex;
  height: 105px;
  padding: 0px 112px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.authHeaderrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.authHeaderrow a {
  text-decoration: none;
}

.authHeaderrow a {
  color: #40637D;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.HeaderNavLinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.authHeaderrowBtns {
  color: #40637D;
  text-align: center;
  border: none;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: none;
  border: none;
  margin-left: 10px; 
}

.authLogo {
  width: 90px;
  height: 90px;
}
.yellowLabel {
    color: #FACF39;
    text-transform: uppercase;
  }
.yellowUserLabel {
    color: #FACF39;
  }
.authHeaderLabel {
    color: #2F485B;
  }
.AuthBannerSetup {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    margin-top: 7em;
  }
.authFirstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  align-self: stretch;
}
.mainBannerSetup {
  float: inline-end;
}
.CommunityContent {
  margin-bottom: 4em;
  margin-top: 4em;
}

.latestExperiencesDiv {
  border-radius: 200px 0px 100px 0px;
  background: rgba(230, 236, 243, 0.50);
  margin-top: 8em;
  margin-bottom: 2em;
}
.latestExperiencesRow {
  display: flex;
  padding: 64px 112px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.communitiesRow {
  margin-top: 6em;
  margin-bottom: 6em;
}

.TextPadding {
    padding: 0em 14em;
}
.roundedFBBtn {
    border: 1px solid darkgray;
    padding: 0.2em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    border-radius: 30px;
    border: 1px solid #3D5A98;
    background: #3D5A98;
    color: white;

  }
.languageSelect {
  border: none;
  background-color: white;
}

.MiniBannerDiv {
  margin: 0px 15px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.MiniBannerContentDiv {
  background-color: white;
  padding: 1em;
}

.miniHeaderstyles {
  color: #2F485B;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: start;
}
.miniParalines {
  color: #2F485B;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
}
.discoverBlock {
  padding: 0em 4em;
}

.discoverBlockImglogo {
  padding: 0em 11em;
}
.authfollowBtn {
  border-radius: 24px;
  background: #E6ECF3;
  padding: 2px 10px;
  border: 1px solid #E6ECF3;
  color: #2F48FB;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.community_BannerSection {
  width: 480px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
.community_imgs {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 15px;
}
.becomeHostRight {
  border-radius: 200px 0px 100px 0px;
  background: rgba(230, 236, 243, 0.50);
  padding: 8em 6em 8em 8em;
}
.becomeHostLeft {
  padding: 8em 8em;
}
.PreRecordBg {
  padding: 2px 12px;
  background: #E6ECF3;
  border: 1px solid #E6ECF3;
  color: #2F48FB;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.bordersetup {
  border-radius: 0.5em;
}
.MiniNameTag {
  color: #1E2D39;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 0.5em;
  margin-bottom: 0;
}
.MinidescTag {
  color: var(--neutral-500, #737373);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 8px;
  margin-left: 0.5em;
  margin-bottom: 0;
}
.footerBackgrounddiv {
  background-color: #2F485B;
  color: #E6ECF3;
  /* padding: 3em 0em; */
}
.discoverBoxStyle {
  background-color: white;
  border: 0.1em solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5em;
  border-radius: 1em;
  margin-top: 30em;
}
.noButtonStyles {
  background: none;
  border: none;
  color: #E6ECF3;
}
.subscriptionBtn {
  background-color: #FACF39;
  color: #2F485B;
  border-color: #FACF39;
}

.BadgeSellerAuth {
  border-radius: 0.1em !important;
  background-color: #E6ECF3 !important;
  color: #6366F1;
}

.SellerAuthBtns {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #2F485B;
  border: 1px solid #2F485B;
  text-decoration: none;
}
.SellerAuthBtns2 {
  background-color: white;
  color: #2F485B;
  border: 0.1em solid #2F485B;
}

footer {
    font-family: Poppins;
    color: white;
}
.footerlogodownbox {
  width: 352px;
  height: 120px;
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
}
.footerLinksAlignment {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0px;
}
.footerbuttonYellow {
  display: flex;
  margin: 0px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #FACF39;
  border: #FACF39;
  color: #2F485B;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.footerClickA {
  align-self: stretch;
  background: none;
  border: none;
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.footerReserved {
  color: rgba(255, 255, 255, 0.50);
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; 
}
.customLeftArrow {
  background-color: transparent;
  color: #FACF39;
  border: none;
  outline: none;
  font-size: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  left: 0;
}
.customRightArrow {
  background-color: transparent;
  color: #FACF39;
  border: none;
  outline: none;
  font-size: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  right: 0;
}


/* General Styles for Auth Full Body Screens */

.authFullHeader {
  background-color: #2f485b;
  display: flex;
  padding: 0px 112px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.authMainHeaderrowBtns {
  color: #FFFFFF;
  text-align: center;
  border: none;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: none;
  border: none;
  margin-left: 10px; 
}
.authMainHeaderrowBtns:hover {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 800;
}
.mainRowHRsWhite {
  padding: 0;
  margin: 0;
  color: #FFF;
  margin-top: 1em;
  margin-bottom: 1em;
}
.mainRowHRsNone {
  padding: 0;
  margin: 0;
  color: transparent;
  margin-top: 1em;
  margin-bottom: 1em;
}
.authMainLogo {
  width: 100%;
  height: 16vh;
  padding: 2px;
}
.authFullBodyContainer {
  background-color: #2f485b;
  padding-left: 0px;
  padding-right: 0px;
}
.AuthBannerSetup {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-top: 4.2em;
}
.authMainBaseRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  align-self: stretch;
  margin-top: 5.5em;
  margin-bottom: 1em;
}
.authMainButtons {
  background-color: #F9C307;
  color: #2F485B;
  padding: 10px 50px;
  border: none;
  border-radius: 0;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.authMainButtons:hover {
  background-color: #FFFFFF;
  color: #2F485B;
  font-size: 12px;
}
.authMainBanner {
  object-fit: contain;
  width: 100%;
  height: auto;
}
.authMainBanner2Row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.authMainBanner2 {
  object-fit: contain;
  width: 100%;
  height: 50vh;
  margin-top: 1em;
}
.authSmallYellowLabel {
  color: #FACF39;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.authBiglabelSpace5 {
  margin-bottom: 1em;
}
.authBiglabel {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.8px;
}
.authHapifyrYellowLabel {
  color: #F9C307;
}
.authHapifyrGrayLabel {
  color: #d183c9;
}
.authContentLabel {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;
}
.authmain2Pad {
  padding: 0px 70px;
}
.authBig2label {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.8px;
}
.authContent2Label {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.32px;
}
.authMiddleContentRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 10px 100px;
  text-align: center;
}
.authBigLabel3 {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.2px;
  margin-top: 1em;
}
.authContentLabel3 {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
}
.authMiniBanner31 {
  object-fit: cover;
  width: 170px;
  height: 170px;
}

.authBigLabel4 {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.2px;
}
.authContentLabel4 {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.authMiniBanner4_1Image {
  object-fit: cover;
  width: 200px;
  height: 140px;
  padding: 2px;
}
.authMiniBanner4_2Image {
  object-fit: cover;
  width: 200px;
  height: 280px;
  padding: 2px;
}
.authMainBanner5 {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.authMainBanner4DD {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.authContentTextLabel7 {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.25px;
}
/* General Styles for Auth Full Body Screens */









/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
  .AuthHeader {
    padding: 0px;
    height: auto;
  }
  .authLogo {
    width: 70px;
    height: 70px;
  }
  .authHeaderrowBtns {
    margin-left: 5px;
    font-size: 14px;
  }
  .HeaderNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
    background-color: aliceblue;
  }
  .HeaderNavLinks .btn {
    margin-bottom: 10px;
  }
  .AuthBannerSetup {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 6em;
  }
  .mainBannerSetup {
    display: none;
  }
  .latestExperiencesDiv {
    background: rgba(230, 236, 243, 0.50);
    border-radius: 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .latestExperiencesRow {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    padding: var(--Spacing-4, 32px) 16px;
  }
  .customLeftArrow {
    background-color: transparent;
    color: #FACF39;
    border: none;
    outline: none;
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    left: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .customRightArrow {
    background-color: transparent;
    color: #FACF39;
    border: none;
    outline: none;
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    right: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .communitiesRow {
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 15px;
  }
  .community_imgs {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    height: auto;
  }
  .community_BannerSection {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
  }
  .becomeHostRight {
    border-radius: 0;
    background: rgba(230, 236, 243, 0.50);
    padding: 25px;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
  }
  .becomeHostLeft {
    padding: 16px;
  }
  .BadgeSellerAuth {
    border-radius: 0.1em !important;
    background-color: #E6ECF3 !important;
    color: #6366F1;
    display: none;
  }
  .miniHeaderstyles {
    color: #2F485B;
    font-family: Josefin Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
    text-align: center;
  }
  .miniParalines {
    color: #2F485B;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  .footerBackgrounddiv {
    background-color: #2F485B;
    color: #E6ECF3;
    padding: 0;
  }
  .footerLinksAlignment {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .footerReserved {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }


/* General Styles for Auth Full Body Mobile Screens */

.authFullHeader {
  background-color: #2f485b;
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.authMainLogo {
  width: 80%;
  height: 8vh;
  padding: 2px;
}
.mainRowHRsWhite {
  padding: 0;
  margin: 0;
  color: #FFF;
  margin-top: 1em;
  margin-bottom: 1em;
}
.mainRowHRsNone {
  padding: 0;
  margin: 0;
  color: transparent;
  margin-top: 1em;
  margin-bottom: 1em;
}
.authBiglabelSpace5 {
  margin-bottom: 1em;
}
.authMainBanner {
  object-fit: contain;
  width: 100%;
  height: auto;
}
.AuthBannerSetup {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 2em;
}
.responsiveNavbarTooggle {
  background-color: #FACF39;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.responsiveNavbarTooggle:hover {
  background-color: #FFF;
}
.authMainHeaderrowBtns {
  color: #2f485b;
  text-align: center;
  border: none;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: none;
  border: none;
  margin-left: 0px;
}
.authMainHeaderrowBtns:hover {
  color: #2f485b;
  font-size: 20px;
  font-weight: 800;
}
.authMainButtons {
  margin-bottom: 1em;
}
.authMainBanner2 {
  object-fit: contain;
  width: 100%;
  height: auto;
  margin-top: 0em;
}
.authMainBanner2Row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.authmain2Pad {
  padding: 0px 16px;
}
.authContent2Label {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.46px;
}
.authMiddleContentRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 2px;
  text-align: center;
}
.authBigLabel3 {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.2px;
  margin-top: 1em;
}
.authContentLabel3 {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
}
.authMiniBanner31 {
  object-fit: cover;
  width: 220px;
  height: 220px;
}
.authBigLabel4 {
  color: #FFFFFF;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.2px;
}
.authContentLabel4 {
  color: #FFFFFF;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.25px;
}
.authMiniBanner4_1Image {
  object-fit: cover;
  width: 180px;
  height: 180px;
  padding: 3px;
}
.authMiniBanner4_2Image {
  object-fit: cover;
  width: 180px;
  height: 360px;
  padding: 3px;
}
.responsiveAuthBanner5Align {
  text-align: center;
  padding: 0px 2px;
}
.authMainBanner5 {
  object-fit: cover;
  width: 100%;
  height: auto;
  padding: 15px;
  margin-bottom: 1em;
}
.authMainBanner4DD {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.authContentTextLabel7 {
  color: #FFFFFF;
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.25px;
}


/* General Styles for Auth Full Body Mobile Screens */

}
/* end of Mobile Screens */





/* Media Queries for Tablets and Larger Screens */
@media (min-width: 768px) and (max-width: 991px) {
  .authLogo {
    width: 70px;
    height: 70px;
  }

  .authHeaderrowBtns {
    margin-left: 8px;
    font-size: 16px;
  }
}
/* end of Tablets and Larger Screens */