

/* edit republish workshop styles  */


.editUnpublishedWorkshopdiv {
  background-color: #E6ECF3;
  /* box-shadow: 0px 8px 24px -3px #060462; */
}
.progressBardiv {
  width: 80%;
}
.progressBarlabel {
  color: var(--Neutral-900, #111827);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0.2rem;
}
.progressBar {
  display: flex;
  height: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-0, 2px);
  border-radius: 100px;
  background: #D2D4D7;
  flex-shrink: 0;
  align-self: stretch;
}
.progressBarInner {
  border-radius: 8px;
  background: #40637D;
  height: 100%;
  transition: width 0.6s ease;
}
.successProceedButton {
  border-radius: 6px;
  background: #2E637D;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Generic-White, #FFF);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.cancelButton {
  border-radius: 6px;
  background: #F2F2F2;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #2E637D;
  border: 1px solid #2E637D;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.HoverBackOption {
  color: #40637D;
}
.HoverBackOption:hover {
  font-size: 1.5em;
  font-weight: 900;
  color: #40637D;
}
.workshopThumbnailImage {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  background: azure;
}
.labelname {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  margin-top: 1em;
}
.labelContent {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.inputlabel {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
  margin-top: 10px;
}
.inputfield {
  background: var(--generic-white, #FFF);
  border-radius: 5px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 8px 12px;
  resize: none;
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.inputfield .placeholderColor::placeholder {
  color: #888;
}
.formSelect {
  appearance: auto;
  padding: 8px 10px;
  padding-right: 30px; /* Space for the custom icon */
  border-radius: 5px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
.formSelect option {
  padding: 10px;
  background: #fff; /* Background color for the options */
  border: 2px solid var(--Neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #666;
  font-size: 14px;
  line-height: 20px;
}

.uploadIcondiv {
  width: 45px;
  height: 45px;
  position: absolute;
  top: -1.5em;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0px 8px 24px -3px #060462;
}
.uploadThumbnailSection {
  width: 180px;
  height: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 15px;
  border-radius: 6px;
  background: rgba(230, 236, 243, 0.50);
  box-shadow: 0px 8px 24px -3px #060462;
  cursor: pointer;
}
.uploadbtnImage {
  width: 15px;
  height: 18px;
}
.uploadNameTag {
  color: #2F485B;
  text-align: center;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  text-decoration-line: underline;
}
.errorMessage {
  color: red;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}
.successMessage {
  color: green;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  background: #EEF2FF;
  margin-bottom: 1em;
}

/* edit republish workshop styles  */


/* republish the workshop styles  */

.republishBackdrop {
  padding: 2em 2em;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);   
}
.republishBackdrop p {
  display: flex;  
  align-items: center;
  justify-content: center;
  gap: 10px;

}
.republishBackdrop .btn {
  color: var(--Generic-white, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.productDetailsBox {
  width: 95%;
  height: 130px;
  padding: 10px;
  border-radius: 12px;
  background: #40637D;
  box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
  pointer-events: none;
}
.ProductDetailsNameTagSetup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IconSetup {
  width: 34px;
  height: 34px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid #FACF39;
  padding: 5px;
}
.IconSetImage {
  width: 18px;
  height: 18px;
}

.previewProfileImg {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 1000px;
  border: 2px solid var(--Generic-White, #FFF);

}
.flexStyleforUserdiv {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 10px;
}
.productCaption {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.productCaption::first-letter {
text-transform: capitalize;
}
.productDescription {
  display: inline;
  padding: 2px 10px;
  border-radius: 24px;
  background: var(--primary-100, #E0E7FF);
  color: var(--primary-600, #4F46E5);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productSellername {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}
.productSellerdesc {
  text-align: justify;
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}
.ProductBoxLabel {
  color: #FFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.ProductBoxlabelValue {
  color: #FACF39;
  font-family: "Josefin Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.toggleDropdowndiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  color: #40637D;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.toggleDropdownClick {
  background: transparent;
  color: #1E2D39;
  outline: none;
  border: none;
  padding: 0px 20px;
}
.toggleToastStyles {
  width: 90%;
  max-width: 90%;
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.PaymentTermsdiv {
  margin-left: 2em;
  margin-right: 0em;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.PaymentTermsdiv ul li {
  color: #1E2D39;
  list-style: square;
  list-style: disc;
}
.PaymentTermsHeaderDiv {
  background: #E6ECF3;
  margin-left: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.PaymentTermsHeaderFont {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: normal;
}
.PaymentTermsfont {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0rem 1rem;
  text-align: start;
  letter-spacing: normal;
}
.PaddingSetupRow {
  padding: 0em 0em 0em 2em;
}
.PaymentTermsLabel {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.CustomCheckbox {
  width: 60px;
  height: 15px;
  cursor: pointer;
}
.CustomCheckbox:checked::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px; /* Adjust to fit inside the checkbox */
  height: 10px; /* Adjust to fit inside the checkbox */
  background-color: #000; /* Customize checkmark color */
  border-radius: 2px; /* Adjust to fit the border-radius */
}

.LinkTagDirect {
  color: #2F485B;
  font-size: 15px;
  font-weight: 800;
  text-decoration: underline;
  text-decoration-color: lightgray;
}
.LinkTagDirect:hover {
  color: #2E637D;
  font-size: 15px;
  font-weight: 900;
}
.PublishSubmitButtons {
  background: #2E637D;
  border: none;
  border-radius: 6px;
  display: inline-flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.PublishSubmitButtons .disabled {
  background: #7a909b;
  pointer-events: all;
}
.PublishSubmitButtonEdit {
  background: transparent;
  border-radius: 6px;
  border: 1px solid #2E637D;
  color: #2E637D;
  outline: none;
  border-radius: 6px;
  display: inline-flex;
  padding: 8px 6px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.modalContentText {
  color: var(--primary-600, #2E637D);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
}

.modalActions {
  width: 100%;
  margin-top: 20px;
}

.cancelModalButton, .confirmModalButton {
  border-radius: 6px;
  background: #2E637D;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Generic-White, #FFF);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
}

.cancelModalButton {
  background-color: transparent; /* Red */
  color: #000;
  border: 2px solid #2E637D;
}
.cancelModalButton:hover {
  background-color: transparent; /* Green */
  color: #000;
  font-weight: 900;
  border-radius: 2px;
}

.confirmModalButton {
  background-color: #2E637D; /* Green */
  color: var(--Generic-White, #FFF);
}
.confirmModalButton:hover {
  background-color: #2E637D; /* Green */
  color: var(--Generic-White, #FFF);
  font-weight: 900;
  border-radius: 2px;
}

/* republishSuccess */

.Background {
  background-color: #E6ECF3;;
}
.contentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.viewportSetup {
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workshopSuccessIcon {
  width: auto;
  height: auto;
  margin-bottom: 0.5em;
}
.republishSuccessStatus {
  color: #2E637D;
  font-family: "Josefin Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}
.republishSuccessText {
  color: #4F46E5;
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}


/* republish the workshop styles  */