/*  Page Name : sendbird.css 
    Author : Created by Manojj M
 */


.channel-type {
    padding: 0px;
    color: #6210CC;
    align-content: center;
    text-align: center;
  }
.channel-create-button {
    background-color: #62EEAB;
    border-color: #62EEAB;
    width: 100%;
    border: none;
    color: white;
    padding: 14px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius:2px;
    cursor: pointer;
  }
.channel-list {
    background: white;
    flex:1;
    overflow-y: scroll;
    height: 60vh;
    max-height: 70vh;
    scrollbar-width: none;
  }
.channel-list-item {
    background: #fcfcfc;
    padding:12px;
    border-top: 1px solid #ececec;
    display:flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
}

.channel-list-item:hover {
    background:#f0f0f0;
}
  
.channel-list-item:hover .channel-icon {
    visibility: visible;
}
  
  .sort-channels {
    width: 100%;
    font-size: 16px;
    padding: 7px;
    cursor: pointer;
  }
  
  .unarchive {
    transform: rotate(180deg);
  }
  
  .archived-channel {
    padding: 10px;
  }
  
  .last-message {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .channel-list-item-details {
    cursor: pointer;
    overflow: hidden;
    max-width: 75ch;
    text-overflow: ellipsis;
    white-space: normal;
    flex: 1;
    line-height: 20px;
  }
  .channel-list-item-name {
    cursor: pointer;
    overflow: hidden;
    max-width: 75ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    line-height: 20px;
  }

  .channel-list-item-name .last-message {
    cursor: pointer;
    max-width: 75ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    
  }
  
  .channel-list-item-sup {
    margin: 0 0 0 5px;
    font-size: 10px;
  }
  
  .channel-icon {
    visibility:hidden;
    width: 20px;
  }
  
  .channel {
    background: white;
    flex: 3;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 102px;
  }
  
  .channel-fixed-header {
    margin-top: 160px;
  }


/* Message box styles */

  .message-list{
    min-height: 55vh;
    max-height: 58vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  
  .message-item {
    margin: 12px;
    display:flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  
  .message {
    border-radius: 8px;
    width:70%;
    padding: 2px 10px;
    margin-left: 4px;
    box-shadow: -5px 5px 20px -5px rgba(72, 77, 77, 0.6);
  }
  .message .message-sender-name {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .message .message-info {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  .message-from-you .message-sender-name {
    color: var(--Neutral-500, #FFF);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .message-from-you .message-info {
    color: var(--Neutral-500, #f2f2f2);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .message-icon {
    visibility:hidden;
    width: 14px;
    filter: brightness(0) invert(1);
  }

  .message.message-from-you {
    margin-left: auto;
    background-color: #2F485B;
    color: #FFF;
    flex-direction: row;
    margin-right: 4px;
  }
  
  .message-item.message-from-you{
    flex-direction: row;
  }
  
  .oc-message, .oc-message-og-tags{
    padding:12px;
    display:flex;
  }
  
  .oc-message-og-tags {
    flex-direction: column;
    max-width: 50%;
    padding: 5px;
    margin-left: 7vw;
    margin-top: -10px;
    box-sizing: border-box;
    border: 1px solid black;
  }
  
  .oc-message, .message-og-tags {
    padding:12px 0px;
    display:flex;
  }
  
  .message-og-tags {
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .og-tags-title {
    margin: 0 0 10px;
  }
  
  .og-tags-description, .og-tags-url, .metadata-modal-list {
    margin: 0 0 5px;
  }
  
  .og-tags-img, .show-users-status-btn, .members-invite-btn {
    width: 100%;
  }
  
  .oc-admin-message {
    background: rgba(255, 0, 0, 0.05);
  }
  
  .report-notification {
    background: rgba(0, 255, 0, 0.5);
    color: white;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin: 5px 0;
    padding: 5px;
  }
  
  .channelProfilediv {
    display: flex;
  }
  .leave-channel {
    background: none;
    outline: none;
    border: none;
    font-size: 20px;
    padding: 0;
    margin: 0;
    line-height: 0;
  }
  
  .admin-message {
    background: rgba(255, 0, 0, 0.05);
    width: 100%;
    color: black;
    margin-left: 0;
  }
  
  .oc-message-icon, .oc-channel-list-icon{
    visibility:hidden;
    width: 14px;
  }
  
  
  .message:hover .message-icon, .channel-list-item:hover .oc-channel-list-icon, .member-item:hover .message-icon, .double_tick-icon, .member-item-wrapper .message-icon {
    visibility: visible;
  }
  
  .double_tick-icon-read {
    filter: none;
  }
  
  .mute-button {
    margin-top: 3px;
  }
  
  .member-item .message-icon {
    filter: none;
  }
  
  .oc-document-message, .message-user-info, .react-button-wrapper, .reactions-list, .metadata-modal-list, .create-channel-wrapper {
    display: flex;
  }
  
  .message-user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    overflow: auto;
    scrollbar-width: none;
    white-space: nowrap;
  }
  .reactions-list {
    list-style: none;
    margin: 0;
    padding-left: 0;
    background: white;
    border-radius: 8px;
  }
  
  .reactions-list li {
    margin-right: 2px;
    padding: 4px;
  }
  
  .react-button-wrapper {
    margin: 5px 5px 0 0;
    width: 100%;
    justify-content: flex-end;
  }
  
  .react-button-img {
    width: 20px;
    filter: none;
  }
  
  .reactions {
    margin: 5px 0 0;
  }
  
  .reactions-item {
    cursor: pointer;
    margin-right: 5px;
  }
  
  .reactions-item-inner {
    font-size: 10px;
  }
  
  .oc-document-message-icon {
    width: 24px;
  }
  
  .oc-message:hover .oc-message-icon, .message-input .oc-message-icon, .metadata-modal-list .oc-message-icon {
    visibility: visible;
  }
  
  .oc-message-sender-name{
    color:#6210CC;
    margin-left:4px;
    font-weight: 600;
    margin-right:4px;
  }

  
  .channel-header-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
  }
  
  .members-list{
    border-left: 1px solid #cbcbcb;
    padding: 12px;
  }
  
  .member-item{
    padding: 8px 0px 0px 0px;
    cursor: pointer;
    font-weight: 600;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 4px;
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .member-item-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
  }
  
  .member-item:hover{
    background:#f0f0f0;
  }
  
  .member-item-name{
    margin-left:12px;
  }
  
  .member-list-btn {
    margin: 0 0 5px;
  }
  
  .member-selected{
    color: #62EEAB;
  }
  
  .operators-list {
    padding: 20px 0;
    font-weight: bold;
  }
  
  .operator-item {
    padding: 8px 0px 0px 0px;
    cursor: pointer;
    font-weight: 600;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 4px;
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .operator-item:hover {
    background:#f0f0f0;
  }
  
  .messageInputdiv {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 2px;
  }
  .messageInputdiv input {
    width: 230px;
    height: 40px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #E5E5E5);
    background: var(--Generic-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  }
  .messageInputdiv .message-input-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  
  .overlay{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ecececb0;
    z-index: 1;
  }
  
  .overlay-content{
    overflow-y: scroll;
    max-height: 400px;
    padding:80px;
    opacity: 1;
    background: white;
    border: 1px solid #cbcbcb;
    width: 40%;
  }
  
  .custom-type-messages {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .custom-type-messages-content {
    overflow-y: scroll;
    max-height: 400px;
    padding:20px;
    opacity: 1;
    background: white;
    border: 1px solid #cbcbcb;
    width: 40%;
  }
  
  .custom-type-button {
    margin: 12px 0 0 12px;
  }
  
  .form-input{
    margin-top: 12px;
    margin-bottom: 12px;
    padding:18px;
    width:100%;
    border-radius: 2px;
  }
  
  .form-button{
    margin-right:4px;
  }
  
  .user-submit-button{
    background-color: #6210CC;
  }
  
  .create-channel-wrapper {
    align-items: baseline;
  }
  
  .channel-create-button{
    background-color: #62EEAB;
    width: 100%;
  }
  
  .create-channel-checkbox {
    width: auto;
    margin: 0 5px 0 10px;
  }
  
  .select-custom-type {
    padding: 12px;
  }
  
  .select-custom-type select {
    width: 100%;
    font-size: 16px;
    padding-top: 12px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .send-message-button{
    background: #714bff;
    border: solid #714bff;
    border-radius: 0.1rem;
    /* width: calc(35% - 8px);
     height: 40px; */
  }
  .sendImg {
      height: 15px;
  }
  .control-button {
    background: inherit;
    padding:4px;
    margin-right:1px;
    border: none;
    outline: none;
  }
  
  .error{
    background:rgb(26, 22, 22);
    display:flex;
    padding:24px;
    width:100%;
  }
  
  .file-upload-label, .user-profile-image-upload-label, .number-of-undelivered-members-btn {
    cursor: pointer;
  }
  
  .file-upload-label img{
    width: 46px;
    vertical-align: middle;
  }
  
  .message-type-add {
    cursor: pointer;
    padding: 12px;
  }
  
  .message-type-label {
    margin: 0 10px;
  }
  
  .message-type-select {
    font-size: 16px;
    padding: 7px;
    outline: none;
    cursor: pointer;
  }


  
  .profile-image-fallback{
    width: 35px;
    background: #2F485B;
    color: #FFF;
    font-weight: 800;
    height: 35px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.profile-image{
width: 35px;
height: 35px;
border-radius: 25px;
object-fit: cover;
}

.typing-indicator {
position: fixed;
bottom:80px;
background-color: #fff;
opacity: 0.8;
margin-left: 20px;
margin-top:20px;
font-weight: 500;
}

.display-none {
display: none;
}

.message-input-column {
flex-direction: column;
}

.freeze-notification {
position: sticky;
top:0;
width: 100%;
line-height: 30px;
opacity: 70%; 
background-color: rgb(173,201,255);
text-align: center;
}

.frozen-icon {
width: 20px;
margin-right: 10px;
}

.underline {
width: 100%;
border-bottom: 1px solid lightgray;
}

.user-copied-message {
position: fixed;
right: 20px;
}

.image-size-error {
width: 100%;
background: rgba(255, 0, 0, 0.2);
padding: 5px;
margin: 5px 0;
text-align: center;
}

.channel-header-wrapper {
display: flex;
justify-content: space-between;
}

.create-metadata-conteiner {
display: flex;
justify-content: space-between;
align-items: baseline;
padding: 12px;
}

.create-metadata-title {
margin: 0;
padding: 5px;
font-size: 16px;
}

.create-metadata-btn {
padding: 5px 10px;
}

.metadata-modal-input {
display: flex;
margin: 0 0 10px;
}

.choise-report-obj {
cursor: pointer;
color: #6210CC;
text-decoration: underline;
}

.number-of-undelivered-members {
padding: 5px 0;
display: flex;
justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
content: attr(data-title);
position: absolute;
border: 1px solid gray;
background-color: darkgray;
border-radius: 5px;
padding: 2px 4px;
font-size: 10px;
}

.banned-muted-users-list {
margin-top: 20px;
}

.show-users-status-btn {
  display: block;
  margin-top: 5px;
}

.user-online-status {
  margin: 0 0 0 5px;
}
.user-profile-title {
  margin-top: 0;
}

.user-profile-image-wrapper, .user-profile-nickname-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.user-profile-nickname-wrapper {
  margin: 0 0 10px;
}

.user-profile-image {
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user-profile-nickname-input {
  width: auto;
}

.user-profile-nickname-button {
  border: none;
  background: transparent;
  color: black;
}

.user-profile-image-upload-label, .user-profile-nickname-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 5px;
}

.number-of-undelivered-members {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.number-of-undelivered-message-btn:hover::after {
  content: attr(data-title);
  position: absolute;
  border: 1px solid gray;
  background-color: darkgray;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 10px;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
display: flex;
}

.do-not-disturb-wrapper, .notifications-snooze-wrapper {
flex-direction: column;
}

.do-not-disturb-wrapper, .do-not-disturb-start-wrapper, .do-not-disturb-end-wrapper, .notifications-snooze-wrapper, .notifications-snooze-input-wrapper {
  margin-bottom: 10px;
}

.notifications-overlay-content {
  padding: 40px;
  width: 50%;
  max-height: 500px;
}

.message-timeStamp {
  /* color: var(--Neutral-900, #171717); */
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.truncated-text {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; /* Adjust based on your font-size */
  max-height: 3em; /* line-height * 2 lines */
}

/* Message box styles */


/* Search Filter Popup */

.search-text-box {
  width: 100%;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  background: var(--Generic-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  font-size: 14px;
}
.search-text-box:focus {
  border:1px solid #f2f2f2;
  outline: none;
  color: #000;
}
.search-box {
  backdrop-filter: blur(10px);
}
.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #E0E7FF;
  border: 1px solid #f2f2f2;
  z-index: 1000;
  padding: 2px 4px;
    
}
.search-item {
  padding: 4px 10px;
}

.filter-container {
  width: 100%;
  height:100%;
  padding:20px;
  border-radius:2px;
  border:1px solid lightgrey;
  font-size:20px;
  animation-name: filters;
  animation-duration: .2s;
  overflow:hidden;
}

@keyframes filters {
  0%{height:0px;}
  25%{height:100px}
  50%{height:200px;}
  75%{height:350px;}
  100%{height:500px;}
}

@-webkit-keyframes filters{
  0%{height:0px;}
  25%{height:100px}
  50%{height:200px;}
  75%{height:350px;}
  100%{height:500px;}
}

.feedfollowBtn {
  background-color: #E0E7FF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 8px;
  border-radius: 25px;
  border: none;
  line-height: 20px;
}

.liveUsercontent {
  font-size: 12px;
  color: var(--neutral-500, #737373);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.profileMessageText {
  color: var(--neutral-900, #171717);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.profileImage {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.list-group-item {
  position: unset !important;
}
.dateUnreaddiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
}
.flexGap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}
.Disabled {
  opacity: 0.4;
  pointer-events: none;
}
/* Search Filter Popup */







  /* General Media Query for Mobile Screens */
  @media (max-width: 767px) {

    .MessagingSection {
      border-radius: 12px;
      background: #FFF;
      padding: 20px 12px !important;
      box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
      border-top: 4px solid #FACF39 !important;
    }
  }
  /* end of Mobile Screens */
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */

