/* general.module.css 

Author     : Manoj M 
Created on : 09-02-2024  */


.aboutUsbox {
    background-color: var(--generic-white, #FFF);
    background: url('/public/Content/Images/General/about_logo_bg.png') no-repeat;
    background-size: 70em 50em;
    background-position: bottom;
    padding: 4em 8em;
}
.Aboutlogodiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.AboutUsText {
    color: #1E2D39;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.36px;
}
.CloseLabel {
    color: #000;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 1.2em;
    cursor: pointer;
}
.AboutHeader {
    color: #2F485B;
    font-family: Josefin Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: -0.8px;
}
.hapifyrText {
    background-color: #FACF39;
    border-radius: 12px;
    padding: 0px 2px;
}

.generalBoxContainer {
    background-color: #E6ECF3;
    padding: 4rem 5rem;
}
.MainBoxSetup {
    background-color: var(--generic-white, #FFF);
    padding: 15px;
    border-top: 0.3em solid rgb(236, 236, 60);
    border-radius: 12px;
}

.leftNavClickSetup {
    display: flex;
    gap: 5px;
    padding: 0.5em;
    background-color: #E6ECF3 !important;
    color: #171717 !important;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
}

.leftNavClickSetup:hover {
    padding: 0.5em;
    background-color: #2F485B !important;
    color: #FFF !important;
}
.mainButtonPP {
    background-color: #FACF39;
    margin-bottom: 1em;
    color: #000;
    width: 11em;
    white-space: nowrap;
    padding: 0px 10px;
    border: 1px solid #FACF39;
    border-radius: 24px;
    font-size: 12px;
}
.mainButtonPP:hover {
    background-color: #FACF39;
    color: #000;
    border: 1px solid #FACF39;
}
.mainHeaderTerms {
    color: #2F485B;
    font-family: Josefin Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.8px;
}
.ContentHeading {
    color: #2F485B;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    font-family: Josefin Sans;

}
.LinkTagDirect {
    color: #2F485B !important;
    font-size: 15px;
    font-weight: 600;
}
.AccordionHeader button {
    font-family: system-ui;
    font-size: 18px;
    font-style: normal;
    /* font-weight: bold; */
}
.AccordionBody {
    color: gray !important;
    font-size: 15px;
    font-weight: 400;
}

.ParaBody {
    color: gray !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
}

.TermsParaBody {
    color: #2F485B !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
}

.TermsSmallHeader {
    color: #2F485B;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.TermsAccordian {
    background-color: #cfe2ff;
    border: none;
}
.TermsAccordian div {
    border: none;
}
.TermsAccordianHeader {
    background-color: #cfe2ff;
}
.TermsAccordianHeader button {
    background-color: #FFF;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0 !important;
}
.TermsAccordianBody {
    background-color: #cfe2ff !important;
}
.boldText {
    font-family: system-ui;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
}
.TermsTagHeading {
    margin-left: 2px;
    margin-right: 10px;
    font-weight: 800;
    display: inline;
    font-size: large;
}
.TermslineHR {
    padding: 0px;
    margin: 0.2rem 0;
    border: 0;
    opacity: 0;
}