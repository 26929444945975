



/* Create Invitation Styles */
  
.newInvitationsBackdrop {
    margin-top: 2em;
}
.newInvitationsBoxsetup {
    background-color: #FFFFFF;
    padding: 1em;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
    border-radius: 20px;
}
.SchedulingHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 1em;
}
.SchedulingHeader {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
}
.inviteButtonStyle {
    background-color: #40637D;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    border-radius: 6px;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
  }

.inviteFormControl {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.inviteFormControlSelectionBox {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    appearance: auto;
}
.inviteFormLabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 5px;
}
.inviteMiddleLabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 10px;
}
.startingWidth, .endingWidth {
    width: 48% !important;
}
.middleWidth {
    width: 4% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.endContents {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.schedulingInputDateTime {
    color: #40637D;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 5px;
    border: 1px solid #ccc; /* Adjust as needed */
    border-radius: 4px;
    width: 95%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    appearance: none; /* Remove default styling (works in most modern browsers) */
}

.schedulingInputDate::placeholder {
    color: #40637D;
}

.inviteFormSelect {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    padding: 4px 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.formDataInvitations {
    overflow: hidden;
    min-height: 50vh;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
}

/* If you need placeholder styling for date inputs, consider adding */
.inviteFormSelect::placeholder {
    color: #40637D; /* Or any color you'd like for the placeholder */
}
.searchDropdown {
    position: static;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #E0E7FF;
    border: 1px solid #f2f2f2;
    z-index: 1000;
    padding: 2px 4px;
}
/* Create Invitations Styles */ 



/* Attendees Styles */

.userSearchfilterInput {
    position: relative;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF;
    border: 2px solid #2F485B;
    padding: 5px 10px;
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}
.clearButton {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 15px;
    color: #2F485B;
    padding: 4px;
}

.UserDatalist {
    padding: 0;
    border: none;
    border-bottom: 1px dotted linen;
    margin-bottom: 5px;
    cursor: pointer;
}
.UserDetailsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}
.UserIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}
.UserBiodataDiv {
    display: grid;
    text-align: justify;
    justify-content: flex-start;
}
.UserNametag {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Adjust based on your font-size */
    max-height: 3em; /* line-height * 2 lines */
}
.UserDescriptionTag {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.nousersfoundFont {
    font-size: 14px;
    color: #1E2D39;
    color: #FF5370;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 10px;
  }

.usersearchDropdown {
    position: absolute;
    background: azure;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2px 4px;
    height: 40vh;
}
.tag {
    background: transparent;
    color: #1E2D39;
    padding: 4px 10px;
    border: none;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.tagRemove {
    border: none;
    padding: 2px 10px;
    font-size: 20px;
    color: #1E2D39;
    border-radius: 20px;
    font-weight: 700;
}
.tagRemove:hover {
    background: #1E2D39;
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 20px;
}
.toggleDropdowndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
  .toggleDropdownClick {
    background: transparent;
    color: #1E2D39;
    outline: none;
    border: none;
    padding: 4px;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .AttendeesUserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }

/* Attendees Styles */



/* Workshop Thumbnail Styles */

.createInvirationButtonRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
}
.joinButtonStyle {
    background-color: #0EB65B;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    border-radius: 25px;
    border: none;
    padding: 2px 10px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
    cursor: pointer;
}
.invitationsContainer {
    padding: 0;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;
}
.invitationsContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    border-radius: 12px;
}
.invitationsRow {
    background-color: white;
    padding: 1em 1em;
    border-radius: 12px;
}
.invitationsThumbnail {
    border-radius: 12px !important;
    width: 100%;
    max-width: 100%;
    min-height: 640px;
    max-height: 650px;
}
.invitationsDescTextImgSize {
    width: 20px;
    height: 20px;
    color: #FFF;
}
.invitationsContainerdiv .invitationsContent {
    position: absolute;
    bottom: 1.5em;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.70);
    background: transparent;
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.invitationsContainerdiv .invitationsContentTextColorFont {
    width: 75%;
    color: #2F485B;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(233, 220, 220, 0.96);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-transform: inherit;
    cursor: pointer;
    
    /* To limit content to 3 lines with ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 1.2em;       /* Set desired line-height */
    max-height: 3.6em;
}
.invitationsWorkshopThumbnail {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    object-fit: cover;
    border: 4px solid #2F485B;
    border-image: fill;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.invitationsContainerdiv .invitationsContentSide {
    position: absolute;
    bottom: 0rem;
    right: 1rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.invitationsContainerdiv .invitationsUserProfilediv {
    background: transparent;
}
.invitationsContainerdiv .invitationsContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
    padding: 4px;
}
.invitationsContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.invitationsIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.invitationsUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.invitationsIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.invitationsIconImg:hover, .invitationsUserImg:hover {
    transform: scale(1.5); /* Scale the image */
}
.boastIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.boastIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.boastIconImg:hover {
    transform: scale(1.8); /* Scale the image */
}
.boastUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.invitationsIconFollowAdddiv {
    display: contents;
}
.invitationsIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.invitationsIconFollowAddButton {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.invitationsIconFollowAddButton:hover {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
  }
  .invitationsIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2.6rem;
    left: 1.0rem;
    transition: transform 0.3s ease; /* Add smooth transition */
  }
  .invitationsIconFollowAddIcon:hover {
    transform: scale(1.5); /* Scale the image */
  }
  .invitationsUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  
  .noInvitationsContainer {
    text-align: center;
    padding: 1em 0em;
    font-size: 1.1em;
    font-weight: bold;
    background: #FFF;
    border-radius: 12px;
  }

  .invitationOptionText {
    color: #DC2626;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
  }
.invitationsJoinButtonsdiv {
    text-align: end;
    margin-bottom: 10px;
}
.invitedJoinButtons {
    border: none;
    color: #FFFFFF;
    background: transparent;
    font-weight: 700;
    font-size: 16px;
    padding: 0px 10px;
    border-radius: 20px;
}
.invitedJoinButtons:hover {
    border: none;
    background: none repeat;
    border-radius: 0;
    text-decoration: underline;
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 20px;

}
.invitedAccept {
    background-color: green;
}
.invitedAccept:hover {
    background-color: green;
}
.invitedReject {
    background-color: red;
}
.invitedReject:hover {
    background-color: red;
}
.acceptedButton {
    background-color: transparent;
    color: green;
}
.acceptedButton:hover {
    background-color: green;
    color: white;
}
.rejectedButton {
    background-color: transparent;
    color: red;
}
.rejectedButton:hover {
    background-color: red;
    color: white;
}

/* Create invitation Styles */

.createdInvitationsContainer {
    padding: 0;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;
}
.createdInvitationsContainerdiv {
    position: relative;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.createdInvitationsRow {
    background-color: white;
    padding: 2em;
    border-radius: 12px;
}
.createdInviteTitleFormControl {
    width: 100%;
    height: auto;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 15px;
    border: none;
    border-bottom: 0.2em solid var(--neutral-200, #E5E5E5);
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    pointer-events: all;
    overflow: hidden;
    resize: none;
    text-overflow: ellipsis; /* Adds "..." if text overflows */
}
.createdInviteFormControl {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 15px;
    border: none;
    border-bottom: 0.2em solid var(--neutral-200, #E5E5E5);
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    pointer-events: all;
}
.createdInvitationsThumbnail {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 6px;
}

.createdInviteDateFormControl {
    width: 95%;
    padding: 8px 12px;
}
.invitationAdmindiv {
    display: inline;
}
.createInviteUserInvite {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
}
.createInviteUserInviteText {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.invitationContainer {
    display: flex;
    align-items: baseline;
}

.invitationContent {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.invitationLink {
    display: flex;
    align-items: baseline;
    text-decoration: none;
}

.invitationImage {
    width: 40px; /* Adjust size as needed */
    height: 40px;
    border-radius: 50%;
}

.invitationText {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    white-space: nowrap; /* Prevents wrapping */
}

.invitationInfo {
    display: inline;
    font-size: 0.9em;
    color: #555;
    white-space: normal; /* Allows text wrapping */
    line-height: 1.2;
}


/* Create invitation Styles */


  
/* Workshop Thumbnail Styles */


/* update invitation section */

.fiMoreHorizontalButton {
    color: #272A31;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    padding: 0px;
    border: none;
}

/* Position the popup relative to the button */
.optionsPopup {
    position: absolute;
    right: 0;  /* Align to the right */
    top: 100%; /* Place it directly below the button */
    background-color: white;
    color: #272A31;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 0;
    min-width: 120px; /* Adjust width as needed */
    z-index: 10;
  }
  
  /* Style individual options in the dropdown */
  .optionsPopup button {
    display: block;
    width: 100%;
    padding: 8px;
    border: none;
    background: none;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    color: #272A31;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
  }
  
  /* Hover effect */
  .optionsPopup button:hover {
    background-color: #f0f0f0;
  }

  .copiedPopup {
    /* display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; */
    top: 1em;
    left: 0;
    right: 0;
    background-color: #4caf50;
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

/* update invitation section */