
/* url="https://hapifyr-content-dev.s3.ap-southeast-2.amazonaws.com/user-feeds/202481610122022995.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAW47MSZHLQC6TSNSS%2F20241107%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20241107T033319Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0yIkYwRAIgdL8M%2F40JBrdmUibWV3YWJjbH0Td%2FqvaLi5InSCdxdEkCIFXFiZCVRpft85q31aPm%2F4JwVYYwWiH9jT3srZzgEEDTKsoFCDwQABoMNDc0NTUzMTc0NDg3Igxwzgvs2IgmW7ZcOnkqpwWHVa5nphC2ocgI4cBCVVJpVfUZYFrxVG5zcnxB0cvgla%2F8IWCgordqIkj0xhawliwCqGVxtqVOiVLV%2Bvj4ZYQRe3O8lQDkEbFWWGX2Nr4rcNln7ApcrsorN%2FqbwVvNishsE%2FFhbE4mYslNYwi10sFkWN%2FTeI9T2ndcy2enkGROBVC5pRAo7E0i3zPxQN3vonrLWCKzT%2F2b%2Frner76dbFcP%2FzwHxatShhpOQcPwCKP43TTjMKVwyolEhJE5rq%2Fgza%2BUojMKbzRzLNvbz0RohTJ3CszpMKWEaVMTTRQiCQGeVaHMmOrnXPjqzkb9ABPj1a9DblhocdHm83swZkNJfbHPWbGtpYoqIt5PpQefoJXsgHnkegB1DTKCwUsAjXjRji%2Bkh1keutGDfGEZnrC1p%2F97T2pklj5FU2gPc7hq8hPL0tjj40JW88bvCaOYRt0nqSzHQ9J7Oi9XEMy5Q5%2BcXIW9uCsflOtgQxEEG6OXtA4iovXP955nkFpd00lLvXpFL97OfYAD1jq9tDRLWOvwLFLOMabDEA0ctpNb9TfCQeS9%2BeiW2s%2BGbeufNuOSA4%2F11ksjNLxDQ%2FME1mAMefLjuJJ%2Fm%2B%2BPS7FlOn6QyiTI77svZAhngEMVAT0XZOaTAWKoXTSixoau0c1B1S4FlIEc9qgvgLpV7Gx5wDXI26tx%2FolgLphRHT01j0AKgPvp7bgQs6%2BxNtDDiTcgXCr8XVBpWhyqlMqTt7reQyrwM5V4Whni59zKnZu54jReCUG82Z3L2yn8L0be%2Fhk5BUSuNwHzW6DhwO9hK1M9brZr842wjT2XCEou89g2Y%2BEsvweeINUI7yPAe0qoGzi9wiGlHlGM0pPTKdLBRFDgmtipberNpOvOWiyabtVlnQyQTxh8JL8NhBswbtaMzq2nMOvQsLkGOrIBWQZvYLwlt2uHNr1cPmhW6fCM37PbGCYVmMv4MU%2FyKLZRNjmk98QNTU93uxh%2F%2F1O7bMbrfOfNOdMUd71xBhpJ8JQ8PrhixvSJVNEadJlo7GpjegGthGXKWCuCNpUrak4lzRyZSjXWkUcFDQ9PRqrxMVNlQ8F%2F7gFrLwfruE3eFuor%2FuRf3UJsQ2H7fp%2FLts55Qr%2BgIPin3E3uX95OQ1yt72sQHUV5IHQ9M%2FonhCYNOUA9CQ%3D%3D&X-Amz-Signature=3c7f4baef379019d127afe8447d14eb550741e8b7d61d10c6987b2552c3affa5&X-Amz-SignedHeaders=host&x-id=GetObject" */



/* Main container */
.viewWatchParty {
  position: relative;
  width: 100%;
  height: 100vh; /* Limit the height to the viewport height */
  background-color: black;
  overflow: hidden; /* Prevent scrollbars */
  box-sizing: border-box;
}

/* Top information section */
.topInfo {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  color: white;
  z-index: 10;
}

.videoTitle {
  font-size: 1.2rem;
  font-weight: bold;
}

.videoType,
.membersJoined {
  font-size: 1rem;
  opacity: 0.7;
}

/* Loading overlay */
.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

/* Video player */
.videoPlayer {
  position: relative;
  width: 100%;
  height: 100%; /* Use full height of the container */
  max-height: 100vh; /* Prevent the player from exceeding viewport height */
}

/* Bottom control section */
.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 4px 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  box-sizing: border-box;
}

.controlRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.controlColLeft,
.controlColRight {
  display: flex;
  gap: 10px;
}

.controlColCenter {
  display: flex;
  justify-content: center;
}

button {
  /* padding: 8px 12px; */
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.controls button {
  background: none;
  border: none;
  color: white;
}

.controls button:hover {
  color: #ddd;
}

.controlsBackground {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  margin: 0px 5px;
}

.controlsBackground button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  padding: 10px;
  border: 1px solid #272A31;
  border-radius: 8px;
}
.controlsBackground .button1 {
  border-radius: 8px 0px 0px 8px;
  border: none;
}
.controlsBackground .button2 {
  border-radius: 0px 8px 8px 0px;
  border: none;
}
.controlsBackground .buttonborderNone {
  border: none;
}
.controlsBackground button:hover {
  background-color: #000;
  border-radius: 8px;
}



.timeSlider {
  margin: 0 10px;
  flex-grow: 1;
}

.handRaised {
  color: #f39c12; /* Highlight color when hand is raised */
}

.timeDisplay {
  cursor: pointer;
  padding: 0 5px;
}


.waveEffect {
  animation: waveAnimation 1s infinite;
}

@keyframes waveAnimation {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}




.startPartyRows {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.joinButtonStyle {
  background-color: #0EB65B;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border-radius: 25px;
  border: none;
  padding: 2px 10px;
  box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10); 
  cursor: pointer;
}


/* Start Watch Party Styles */

.inviteItemContentDiv {
  padding: 0 1rem;
}
.inviteItemTitleFlex {
  display: flex;
  gap: 20px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.inviteItemProfileDiv{
  display: flex;
  justify-content: space-between;
}
.inviteItemTitle {
  color: #40637D;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 5px;
}
.inviteItemProfileLeft {
  display: flex;
  justify-content: space-between;
}
.inviteItemProfileImg {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border-radius: 1000px;
  border: 2px solid var(--Generic-White, #FFF);
}
.inviteItemSellername {
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.inviteItemSellerDesc {
  color: var(--Neutral-500, #737373);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.inviteItemProfileRight {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.inviteItemWatchPartyBtn {
  background: #2E637D;
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 24px;
  color: #FFFFFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

.inviteItemWatchPartyBtn:hover {
  background: #2E637D;
  color: #FFFFFF;
  font-size: 15px;
  cursor: pointer;
}
.toggleToastpartyStyles {
  width: 100%;
}
.toggleDropdowndiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
.toggleDropdownClick {
  background: transparent;
  color: #1E2D39;
  outline: none;
  border: none;
  padding: 0px 20px;
}
.toggleToastStyles {
  width: 90%;
  max-width: 90%;
}
.inviteItemVideoContent {
  width: 100%;
  margin-top: 1rem;
  border-radius: 24px;
}
.inviteItemDescContent{
  margin: 2rem 0rem;
}
.inviteItemDescTitle{
  color: #40637D;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.inviteItemDescText{
  color: #1E2D39;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.inviteItemExtraMaterialContent {
  margin: 2rem 0rem;
}
.inviteItemProductData {
  color: red;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

/* Custom CSS for ReactPlayer controls background */

.inviteItemReactplayer {
  width: 100% !important;
  height: 400px !important;
  border: none;
  border-radius: 12px;
}

.inviteItemReactplayer > video {
  border-radius: 12px;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  background-color: #000;
}

.customCarousel .carousel .control-dots {
  top: 10px; /* Adjust as needed to position at the top */
  bottom: auto;
}

.customCarousel .carousel .control-dots .dot {
  background: #fff; /* Customize the color */
}

.customCarousel .carousel .control-dots .dot.selected {
  background: #000; /* Customize the color */
}

/* Fixing arrow buttons */
.arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #FACF39;
  color: #171717;
  border: none;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

}

.arrowLeft {
  left: 10px;
}

.arrowRight {
  right: 10px;
}

.arrowLeft:hover, .arrowRight:hover {
  background: #1E2D39; /* Hover background color */
  color: #FFF;
}

/* Custom CSS for ReactPlayer controls background */


/* Start Watch Party Styles */
