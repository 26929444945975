/* CSS Styles for Styles

  Index.css created by Manojj M  */


body {
  margin: 0;
  padding: 0;
  color: #1E2D39;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.18px;
}
code {
  font-size: 1em;
  font-family: Open Sans;
  color: rgba(47, 72, 91, 1);
}
.btn {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #40637d !important;
  --bs-btn-bg: #40637d;
  --bs-btn-border-color: #40637d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #40637d;
  --bs-btn-hover-border-color: #40637d;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #40637d;
  --bs-btn-active-border-color: #40637d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #40637d;
  --bs-btn-disabled-border-color: #40637d;
}
.btn-outline-primary {
  --bs-btn-color: #40637d !important;
  --bs-btn-border-color: #40637d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #40637d;
  --bs-btn-hover-border-color: #40637d;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #40637d;
  --bs-btn-active-border-color: #40637d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #40637d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #40637d;
  --bs-gradient: none;
}
.nav-tabs {
  --bs-nav-tabs-border-width: none !important;
}
.scroll-container {
  width: 300px;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.show-scrollbar {
  overflow-y: scroll !important;
}
input {
  border: none;
}
.offcanvas {
  width: 70% !important;
}
.form-control::placeholder {
  color: rgba(163, 163, 163, 0.50);
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.form-control option {
  padding: 10px;
  background: #fff; /* Background color for the options */
  border: 2px solid var(--Neutral-200, #E5E5E5);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: #666;
  font-size: 14px;
  line-height: 20px;
}

.outer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #152128f0;
	height: 100vh;
	width: 100vw;
	color: rgb(191, 210, 210);
}

.custom-slider {
	width: 70%;
	margin: 0 auto;
}
