



.follwersSection {
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);

}
.follwersBlocks {
    /* display: flex;
    align-items: baseline;
    justify-content: space-between; */
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.follwersBlocks .tab-content {
  display: none;
}
.customTabHeaders {
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.customTabHeader {
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.customTabClickActive {
    padding: 10px 0px;
    bottom: 1em;
    border-color: none !important;
    border: none;
    color: #2E637D;
    border-bottom: 0.2em solid #2E637D;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.followerHeight {
  height: 65vh;
}
.followerCard {
    background-color: #FFF;
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    margin-bottom: 1em;
}
.followerBlockCard {
  background-color: #FFF;
  max-height: 65vh;
  overflow: inherit;
  scrollbar-width: none;
  margin-bottom: 1em;
}
.noUsersFoundMsg {
    color: #ff4d4f; /* Error color */
    font-size: 16px;
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    padding: 20px;
}
.followerInfodiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.3em;
    margin-top: 0.3em;
}
.followerDataDiv {
    overflow: auto;
    line-height: 10px;
    margin-left: 10px;
}
.userinviteData {
    cursor: pointer;
    text-align: start;
}
.inviteUserImg {
    width: 40px;
    height: 40px;
    border-radius: 400px;
    border: var(--Spacing-0, 2px) solid var(--Generic-White, #FFF);
}
.inviteusername {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.inviteuserdesc {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
}
.userDataContainer {
    display: flex;
    gap: 5px;
    flex-direction: row;
}
.menuContainer {
    position: relative;
    display: inline-block;
  }
  
  .threeDotButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;

  }
  
  .menuDropdown {
    position: absolute;
    top: 25px;
    right: 0;
    background: #FFF;
    border: 1px solid #FFF;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 5px 0;
    margin: 0;
    z-index: 1000;
  }
  
  .menuDropdown li {
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  
  .menuDropdown li:hover {
    background-color: #f0f0f0;
  }
  
  .addConnectionsSection {
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    padding: 25px 20px;
}

.formSearchControl {
    display: flex;
    padding: 8px var(--Spacing-3, 12px);
    align-items: center;
    gap: 29px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--Neutral-200, #E5E5E5);
    background: var(--Generic-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.formSearchLabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0px;
}

.suggestionsdiv {
    background-color: #FFF;
    min-height: 30vh;
    max-height: 40vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
.addConnectionBtnRow {
    display: flex;
    justify-content: flex-end;
}
.addConnectionBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 6px;
    border-radius: 6px;
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.addConnectionBtn:hover {
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
}



.inputContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px;
  }

  .input {
    flex: 1;
    border: none;
    padding: 8px;
    outline: none;
    min-width: 100px;
    outline: none;
  }
  .input:hover {
    flex: 1;
    border: none;
    outline: none;
  }
  
  .suggestionTag {
    padding: 4px 8px;
    margin: 4px 4px 4px 0;
    background-color: #e0e0e0;
    border-radius: 12px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .suggestionsdiv {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 1em;
  }
  
  .suggestionsContainer {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
  
  .suggestionItem {
    background-color: #f0f0f0;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .suggestionItem:hover {
    background-color: #d0d0d0;
  }
  
  .noSuggestions {
    color: #888;
    margin: 8px 0;
  }
  









  
  /* General Media Query for Mobile Screens */
  @media (max-width: 767px) {

    .responsiveDisplay {
      display: none;
    }
    .follwersSection {
      padding: 5px 15px;
    }
    
  }
  /* end of Mobile Screens */
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */