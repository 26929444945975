.ulliData {
    height: 6em;
    overflow: auto;
    list-style: none;
}

.mainPad {
    padding: 0em 2em;
}

.SellardbContentDiv {
    background: #F2F2F2;
}

.profileBox {
    background-color: white;
    padding: 0.5em 1.5em;
}

.SellerNameStyle {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: -0.56px;
}
.SellerBioStyle {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.Card {
    background-color: #E0E0FC;
    margin-right: 1em;
}

.sellerProgressbar {
    height: 2vh;
    margin-top: 3.5%;
    background: lightgray !important;
}

.custom-progress-bar {
    background-color: green;
    color: white;
}

.sellerReviewBox {
    background-color: white;
    padding: 0.5em;
    margin-left: 1em;
}
.cardBody {
    box-shadow: none;
}
.sellerRatingBox {
    background-color: white;
    padding: 0.5em;
    margin-right: 1em;
}
.customAlign {
    justify-content: flex-end !important;
    text-align: end;
}
.sellerGraphBody {
    background-color: black;
}
.CreateExperienceBtnRow {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #FACF39;
}
.CreateExperienceBtn {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
    background: none;
}
.headerText {
  font-family: Josefin Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.8px;
}
.publishedTestStatus {
    font-size: 14px;
    color: darkgreen;
    font-weight: 100;
    font-style: normal;
}
.userWorkshopsListTable {
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    box-shadow: none;
}
.userWSImage {
    width: 80px;
    height: 50px;
    border-radius: 12px;
    margin-right: 10px;
}
.userWSContentBox {
    display: grid;
    text-align: justify;
    line-height: 20px;
}
.userWSContentBox h3 {
    color: #2F485B;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 4px;
}
.userWSContentBox label {
    color: #40637D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

/* CSS to truncate text */
.truncatedText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* CSS for tooltip display */
.truncatedText:hover {
    position: relative;
}

.truncatedText:hover::before {
    content: attr(title);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 999;
}

/* CSS to truncate text */
.truncatedText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.truncatedText::after {
    content: attr(data-tooltip);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.truncatedText:hover::after {
    visibility: visible;
    opacity: 1;
}

.userWorkshopsTable {
    vertical-align: middle;
    border-color: aliceblue;
  }
  
.userWorkshopsTable th {
    background-color: #40637D;
    color: #FFF;
    text-align: center;
    padding: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 400;
}
.TBodyRowHover {
    background-color: #40637D;
    white-space: nowrap;
}

.userWorkshopsTable thead, .userWorkshopsTable tbody {
    border-radius: 10px;
}

.userWorkshopsTable td {
    background-color: #FFF;
    color: #737373;
    text-align: center;
    padding: 2px 6px;
    font-weight: 600;
}

  .userWorkshopsTable th,
  .userWorkshopsTable td {
    width: calc(100% / 4);
  }

  .EducationImageSection {
    padding: 0;
    background: none;
    border: none;
  }


/* unpublished workshops */

.feedsContainerBox {
    text-align: center;
    background: #FFF;
    color: #2E637D;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    margin-bottom: 3rem;
  }
.FeedBody {
    border-radius: 12px;
    background: #FFF;
    padding: 0.8rem;
}

.feedContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
 .feedThumbnail {
    border-radius: 12px !important;
    width: 100%;
    max-width: 100%;
    min-height: 600px;
    max-height: 650px;
 }
 .feedDescTextImgSize {
    width: 20px;
    height: 20px;
 }
.feedContainerdiv .feedContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.70);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.feedContainerdiv .feedContentTextColorFont {
    width: 80%;
    text-align: start;
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-transform: inherit;
}
.feedContentTextColorFont::first-letter {
    text-transform: uppercase;
  }
  .feedContainerdiv .feedContentSide {
    position: absolute;
    bottom: 3rem;
    right: 1rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.feedContainerdiv .feedUserProfilediv {
    background: transparent;
}
.feedContainerdiv .feedContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
    padding: 4px;
}
.feedContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.feedContainerdiv .boastContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(31, 30, 30, 0.7);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.feedContainerdiv .boastReviewText {
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.feedIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.feedUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
}
.feedIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.feedIconImg:hover {
    transform: scale(1.4); /* Scale the image */
}
.feedIconFollowAdddiv {
    display: contents;
}
.feedIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.feedIconFollowAddButton {

    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddButton:hover {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2.6rem;
    left: 1.2rem;
}
.feedUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.mainTitleHeader {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: -0.56px;
}
.workshopsCountBg {
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: #FACF39 !important;
    color: var(--Neutral-700, #404040);
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
}
.editBadgeClick {
    display: inline-flex;
    padding: 2px 10px;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    background: transparent !important;
    border: 1px solid #2E637D !important;
    box-shadow: 0 2px 12px rgba(18, 22, 6, 1.1);
    color: #2E637D;
    text-align: center;
    font-family: "Josefin Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 2rem;
}

.viewLabelClick {
    cursor: pointer;
    color: #26DC4E;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    margin: 0;
}

/* unpublished workshops */






  
/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .responsiveDisplay {
      display: none;
    }
  
  
  }
  /* end of Mobile Screens */
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {
  
  }
  /* end of Tablets and Larger Screens */