

.mainHostdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.hostProfileDiv {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
}
.hostNameStyleTag {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}
.sellerCaptionStyle {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}
.hostDescStyleTag {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
}
.sellerProfilePic {
    width: 56px;
    height: 56px;
    border: 2px solid var(--Generic-White, #FFF);
}


/* FeedPost Styles */

.FeedBody {
    border-radius: 12px;
    background: #FFF;
    padding: 0.8rem;
}

.feedContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
 .feedThumbnail {
    border-radius: 12px !important;
    width: 100%;
    max-width: 100%;
    min-height: 500px;
    max-height: 650px;
 }
 .feedDescTextImgSize {
    width: 20px;
    height: 20px;
 }
.feedContainerdiv .feedContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.70);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.feedContainerdiv .feedContentTextColorFont {
    width: 80%;
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-transform: inherit;
}
.feedContentTextColorFont::first-letter {
    text-transform: uppercase;
  }
  .feedContainerdiv .feedContentSide {
    position: absolute;
    bottom: 3rem;
    right: 1rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.feedContainerdiv .feedUserProfilediv {
    background: transparent;
}
.feedContainerdiv .feedContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
    padding: 4px;
}
.feedContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.feedContainerdiv .boastContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(31, 30, 30, 0.7);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.feedContainerdiv .boastReviewText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.feedIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.feedUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
}
.feedIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
}

.feedIconFollowAdddiv {
    display: contents;
}
.feedIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.feedIconFollowAddButton {

    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddButton:hover {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2.6rem;
    left: 1.2rem;
}
.feedUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.mainTitleHeader {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px;
    letter-spacing: -0.56px;
}
.workshopsCountBg {
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: #FACF39 !important;
    color: var(--Neutral-700, #404040);
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
}

/* FeedPost Styles */


.boastIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.boastIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
}
.boastUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}


.customTabHeader {
    color: #000;
    font-weight: 600;
}

.PublicProfileClickActive {
    border: none;
    color: #2F48FB;
    border-bottom: 0.2em solid #2F48FB;
    font-weight: 800;
    font-size: 18px;
    border-color: none !important;
    bottom: 1em;
    padding: 10px 0px;
}

.stickyRow {
    position: sticky;
    top: 5em;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Scrolling content area */
.scrollableContent {
    overflow: hidden; /* Ensure no content shows outside the viewport */
    position: relative; /* Allows control over child stacking */
}

/* fire animation */
@keyframes fireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(2.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes disfireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(0.8); }
    100% { transform: scale(1); }
  }
  
  .animateFire {
    animation: fireAnimation 1.5s ease-in-out;
  }
  
  .animateDisfire {
    animation: disfireAnimation 0.5s ease-in-out;
  }
/* fire animation */





/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .responsiveDisplay {
      display: none;
    }
  
  }
  /* end of Mobile Screens */
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {
  
  }
  /* end of Tablets and Larger Screens */