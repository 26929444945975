
.FeedBody {
    border-radius: 12px;
    background: #FFF;
    padding: 1.2rem;
}
.FeedProfileSetup {
    width: 55px;
    height: 55px;
}
.feedfirstrow {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}
.feedUserContent {
    width: 65%;
    line-height: normal;
}

.feedContentdata {
    display: flex;
    width: 550px;
    height: 350px;
    justify-content: center;
    align-items: center;
}
.feedBtnCloseDiv {
    padding: 0em;
}
.feedBtnClose {
    padding: 0em 0em 0em 1em;
    text-decoration: blink;
    color: gray;
    font-size: 33px;
}
.UserClickBtns {
    background: none;
    border: none;
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.UserClickBtnsSelected {
    background: #0b5ed7;
    color: white;
}
.feedfollowBtn {
    background-color: #E0E7FF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 1px 12px;
    border-radius: 24px;
    border: none;
}
.feedfollowBtn:hover {
    background-color: #2F485B;
}

.CardTitle {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0.2em;
}
.CardText {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    word-wrap: break-word;
}

.FeedImageRow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.PreRecordBg {
    background: var(--primary-50, #EEF2FF);
    padding: 0.4em 1em;
    color: #4F46E5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.PriceBg {
    background-color: #EEF2FF !important;
    color: #4F46E5;
    border: 2px solid #f2f2f2;
}

/* Feed Thumbnail Styles */
.feedContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
 .feedThumbnail {
    border-radius: 12px !important;
    width: 100%;
    max-width: 100%;
    min-height: 640px;
    max-height: 650px;
 }
 .feedDescTextImgSize {
    width: 20px;
    height: 20px;
 }
.feedContainerdiv .feedContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.70);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.feedContainerdiv .feedContentTextColorFont {
    width: 80%;
    color: #2F485B;
    text-shadow: 0px 4px 1000px rgba(233, 220, 220, 0.96);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-transform: inherit;
}
.feedContentTextColorFont::first-letter {
    text-transform: uppercase;
  }
.feedContainerdiv .boastContent {
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.70);
    width: 100%;
    padding: var(--Spacing-3, 10px 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.feedContainerdiv .boastReviewText {
    width: 80%;
    color: #2F485B;
    text-shadow: 0px 4px 1000px rgba(233, 220, 220, 0.96);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.boastReviewText::first-letter {
    text-transform: uppercase;
  }

.feedContainerdiv .feedContentSide {
    position: absolute;
    bottom: 3rem;
    right: 1rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.feedContainerdiv .feedUserProfilediv {
    background: transparent;
}
.feedContainerdiv .feedContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
    padding: 4px;
}
.feedContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.feedIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.boastPostWorkshopThumbnail {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.feedUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.feedIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.feedIconImg:hover, .feedUserImg:hover {
    transform: scale(1.5); /* Scale the image */
}

.boastIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.boastIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
    transition: transform 0.3s ease; /* Add smooth transition */
}

.boastIconImg:hover {
    transform: scale(1.8); /* Scale the image */
}
.boastUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.feedIconFollowAdddiv {
    display: contents;
}
.feedIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.feedIconFollowAddButton {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddButton:hover {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    outline: none;
}
.feedIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2.6rem;
    left: 1.0rem;
    transition: transform 0.3s ease; /* Add smooth transition */
}
.feedIconFollowAddIcon:hover {
    transform: scale(1.5); /* Scale the image */
}
.feedUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.shareCopyText {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.URLlinkshow {
    color: #2f48fb !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0.5em;
    margin-bottom: 1em;
    background: none;
    border: none;
    outline: none;
    opacity: 1 !important;
}
.URLlinkshow:disabled {
    color: inherit;
    text-decoration: none;
    cursor: no-drop;
    pointer-events: none;
}
.ClickCopyBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 6px;
    border-radius: 6px;
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.ClickCopyBtn:hover {
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
}

/* fire animation */
@keyframes fireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(2.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes disfireAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(0.8); }
    100% { transform: scale(1); }
  }
  
  .animateFire {
    animation: fireAnimation 1.5s ease-in-out;
  }
  
  .animateDisfire {
    animation: disfireAnimation 0.5s ease-in-out;
  }
/* fire animation */

/* Feed Thumbnail Styles */






  /* General Media Query for Mobile Screens */
  @media (max-width: 767px) {

    .FeedBody {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
        padding: 10px;
    }
    .feedThumbnail {
        border-radius: 12px !important;
        width: 100%;
        min-height: 440px;
        max-height: 450px;
     }
     .feedContainerdiv .feedContentdiv {
        padding: 0;
    }
    .feedIconFollowImg {
        position: relative;
        width: 45px;
        height: 45px;
        border: 5px solid #FFF;
    }
    .feedIconFollowAddIcon {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 1.8rem;
        left: 0.95rem;
        transition: transform 0.3s ease; /* Add smooth transition */
    }
    .feedIconImg {
        width: 25px;
        height: 25px;
        cursor: pointer;
        transition: transform 0.3s ease; /* Add smooth transition */
    }
    .feedUserImg {
        width: 45px;
        height: 45px;
        border: 2px solid #2F48FB;
        transition: transform 0.3s ease; /* Add smooth transition */
    }
    .feedIconImg:hover, .feedIconFollowAddIcon:hover {
        transform: scale(1.5); /* Scale the image */
    }
    .feedContainerdiv .boastReviewText {
        width: 70%;
    }
    .feedContainerdiv .feedContentTextColorFont {
        width: 70%;
    }
    .feedContainerdiv .feedContent {
        background: rgba(255, 255, 255, 0.70);
        width: 100%;
        padding: var(--Spacing-3, 6px 8px);
    
    }
  }
  /* end of Mobile Screens */
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */