
.MainpartSetup {
    padding-top: 4.8em;
    background-color: #F2F2F2;
}

.pagination {
    display: inline-block;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
  }
  
  .pagination a.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }

  .feedsContainerBox {
    text-align: center;
    background: #FFF;
    color: #2E637D;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    margin-bottom: 3rem;
  }

.stickySidebar {
    position: sticky;
    top: 85px; /* Keeps it at the top of the viewport while scrolling */
    height: 100vh;
}

.leftComponentScroll {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 100%; /* Ensures it fills the height */
    scrollbar-width: thin; /* Thin scrollbar for Firefox */
    scrollbar-width: none; /* Thin scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
}

.leftComponentScroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome, Safari, Opera */
}


/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
  .responsiveDisplay {
    display: none;
  }

}
/* end of Mobile Screens */




/* Media Queries for Tablets and Larger Screens */
@media (min-width: 768px) and (max-width: 991px) {

}
/* end of Tablets and Larger Screens */