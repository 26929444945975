
/* loading.module.css */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
  z-index: 9999;
}
  
.loadingSpinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
.loadingOverlaydiv {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
.loadingOverlayText {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  